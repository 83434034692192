export const versions = [
  {
    version: "1.1.0",
    releaseDate: "Thursday, August 13th, 2020",
    details: "Authentication improvement."
  },
  {
    version: "1.0.0",
    releaseDate: "Wednesday, April 1st, 2020",
    details: "EventX Tracker is ready! Enjoy."
  }
]