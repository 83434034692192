import React from "react";
import { useDispatch } from "react-redux";
import SwitchToggle from "../SwitchToggle/SwitchToggle";
import { clearTagsStatus } from "../../Store/Actions/app";
import { ITag } from "../../Store/Reducers/event";
import icons from "../../Assets/standard-2.0.2/images/24/em-icons.svg";
import "./EventFilterModal.scss";

interface IProps {
    tags: Array<ITag>;
    filterCallback: Function;
    closeCallback: Function;
}

const EventFilterModal: React.FC<IProps> = ({
    tags,
    filterCallback,
    closeCallback,
}) => {
    const dispatch = useDispatch();

    const clearCallback = () => {
        dispatch(clearTagsStatus());
    };

    return (
        <div className="overlay">
            <div className="event-filter-modal">
                <div className="event-filter-modal__header--close">
                    <div className="event-filter-modal__header--title">
                        Tag Filters
                    </div>
                    <svg
                        className="event-filter-modal__header--close-icon em-c-icon em-c-icon--small"
                        onClick={() => closeCallback()}
                    >
                                    {" "}
                        <use
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            href={`${icons}#x-filled`}
                        ></use>
                                    {" "}
                    </svg>
                </div>
                <div className="event-filter-modal__header">
                    <div
                        className="event-filter-modal__header--clear-btn"
                        onClick={() => clearCallback()}
                    >
                        Clear
                    </div>
                </div>
                <div className="event-filter-modal__list">
                    {tags.length > 0 ? (
                        tags.map((tag: ITag, idx: number) => (
                            <div
                                key={tag.name}
                                className="event-filter-modal__list-switch"
                            >
                                <SwitchToggle
                                    label={tag.name}
                                    callback={() => filterCallback(idx)}
                                    isActive={tag.isActive}
                                />
                                <hr
                                    style={{
                                        display:
                                            idx !== tags.length - 1
                                                ? "block"
                                                : "none",
                                    }}
                                />
                            </div>
                        ))
                    ) : (
                        <div className="event-filter-modal__error">
                            No tags to filter
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EventFilterModal;
