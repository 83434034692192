import {IUser} from '../Reducers/user'
import {updateErrorResponse} from '../Actions/app'
import UserService from '../../Services/UserService'

let controller:AbortController
let signal:AbortSignal

export const updateEventXTrackerToken = (token:string) => {
    return (dispatch:any) => {
        dispatch({type:'UPDATE_EVENTX_TRACKER_TOKEN', payload:token})
    }
}

export const updateUserList = (value:string, userType:string) => {
    if (controller !== undefined) {
        controller.abort();
    }
    controller = new AbortController()
    signal = controller.signal
    const request = UserService.getUsersAsync(value, userType, signal)
    return (dispatch:any) => {
        request.then((response:any) => {
            if(response.value.length !== 0){
                let results:Array<IUser> = response.value.map((user:any) => {
                    return {
                        id: user.id, 
                        name: user.displayName, 
                        email: user.mail,
                        lanId: user.extension_d6448b80620845bf89c63bb6933c28da_XOM_FullLANID
                    }
                })
                dispatch({type:'UPDATE_USER_LIST', payload:results})
                dispatch(updateUserNotFound(false))
            } else {
                dispatch({type:'UPDATE_USER_LIST', payload:[]})
                dispatch(updateUserNotFound(true))
            }
        }).catch((error) => {
            if(error.status === 401){
                dispatch(updateErrorResponse({code:"401", message:"Session Expired"}))   
            } else if (error.name === 'AbortError'){
                console.error("The user aborted a request.")                  
            }
        })
    }           
}

export const updateEventXUser = (user:any) => {
    return (dispatch:any) => {
        dispatch({type:'UPDATE_EVENTX_USER', payload:user})
    }
}

export const updateUser = (user:IUser) => {
    return (dispatch:any) => {
        dispatch({type:'UPDATE_USER', payload:user})
    }
}

export const clearUserStore = () => {
    return (dispatch:any) => {
        dispatch({type:'CLEAR_USER_STORE'})
    }
}

export const updateUserNotFound = (value:boolean) => {
    return (dispatch:any) => {
        dispatch({type:'UPDATE_IS_USER_NOT_FOUND', payload:value})
    }
}