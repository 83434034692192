import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {updateSetting} from '../../Store/Actions/setting'
import {ISetting} from '../../Store/Reducers/setting'
import SwitchToggle from '../SwitchToggle/SwitchToggle'
import './OptionsSetting.scss'

const OptionsSetting:React.FC = () => {   
    const dispatch = useDispatch()
    const settingList = useSelector((state:any) => state.setting.settingList)

    const setNewSetting = (settingName:string) => {
        dispatch(updateSetting(settingName))
    }

    return (
        <div className="options-setting">
            <div className="options-setting__content em-l-container">
                <div className="options-setting__content--title">Settings</div>
                {settingList && settingList.map((setting:ISetting) => 
                <SwitchToggle 
                    key={setting.name} 
                    optionalClass="options-setting__content--options" 
                    label={setting.name} 
                    callback={() => setNewSetting(setting.name)} 
                    isActive={setting.isActive} 
                    isDisable={setting.isDisable}
                />
                )}
            </div>
        </div>
    )
}

export default OptionsSetting