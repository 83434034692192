import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {updateFilterType} from '../../Store/Actions/app'
import './EventTypesBar.scss'

const EventTypesBar:React.FC = () => {
    const filterType = useSelector((state:any) => state.app.filterType)
    const dispatch = useDispatch()

    const selectTypeCallback = (type:any) => {
        dispatch(updateFilterType(type))
    }

    return (
        <div className="event-types">
            <button className={`em-c-btn ${filterType === 'All' ? "selected" : "" }`} onClick={() => selectTypeCallback('All')} >
                <span className="em-c-btn__text">{"All"}</span>
            </button> 
    
            <button className={`em-c-btn ${filterType === 'Upcoming' ? "selected" : "" }`} onClick={() => selectTypeCallback('Upcoming')}>
                <span className="em-c-btn__text">{"Upcoming"}</span>
            </button>
        
            <button className={`em-c-btn ${filterType === 'Completed' ? "selected" : "" }`} onClick={() => selectTypeCallback('Completed')}>
                <span className="em-c-btn__text">{"Completed"}</span>
            </button>
        
            <button className={`em-c-btn ${filterType === 'Cancelled' ? "selected" : "" }`} onClick={() => selectTypeCallback('Cancelled')}>
                <span className="em-c-btn__text">{"Cancelled"}</span>
            </button>
        </div>
    )
}

export default EventTypesBar
