import { useRef, useEffect, useState } from "react";
import AttendeeCard from "../AttendeeCard/AttendeeCard";
import { useSelector } from "react-redux";
import { IAttendee } from "../../Store/Reducers/attendee";
import icons from "../../Assets/standard-2.0.2/images/24/em-icons.svg";
import "./AttendeeListPane.scss";

const AttendeeListPane: React.FC = () => {
    const attendeeList = useSelector(
        (state: any) => state.attendee.attendeeList
    );
    const [isFilterExpand, setIsFilterExpand] = useState(false);
    const [currentAttendees, setCurrentAttendees] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfPage, setNumberOfPage] = useState(1);
    const [searchInput, setSearchInput] = useState("");
    const [isCheckInFilterd, setIsCheckInFilterd] = useState(true);
    const [isWalkInFilterd, setIsWalkInFilterd] = useState(true);
    const [isNotCheckInFilterd, setIsNotCheckInFilterd] = useState(true);
    const inputRef = useRef(document.createElement("input"));
    const rowPerPage = 20;

    let lastIndex = pageNumber * rowPerPage;
    let firstIndex = lastIndex - rowPerPage;

    const searchAttendee = (name: string) => {
        setSearchInput(name);
        filterAttendee(name);
    };

    const filterAttendee = (name: string) => {
        let newList = attendeeList.filter((attendee: IAttendee) =>
            attendee.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
        );
        newList = newList.filter((attendee: IAttendee) => {
            if (isCheckInFilterd && attendee.userStatus === "checked-in")
                return true;
            if (isWalkInFilterd && attendee.userStatus === "walked-in")
                return true;
            if (isNotCheckInFilterd && attendee.userStatus === "not-checked-in")
                return true;
            return false;
        });
        let newNumberOfPage = Math.ceil(newList.length / rowPerPage);
        setNumberOfPage(newNumberOfPage);
        if (newNumberOfPage < pageNumber) {
            setPageNumber(1);
        }
        newList = newList.slice(firstIndex, lastIndex);
        setCurrentAttendees(newList);
    };

    useEffect(() => {
        setNumberOfPage(Math.ceil(attendeeList.length / rowPerPage));
    }, []);

    useEffect(() => {
        lastIndex = pageNumber * rowPerPage;
        firstIndex = lastIndex - rowPerPage;
        filterAttendee(searchInput);
    }, [pageNumber]);

    useEffect(() => {
        filterAttendee(searchInput);
    }, [isCheckInFilterd, isWalkInFilterd, isNotCheckInFilterd]);

    useEffect(() => {
        if (isFilterExpand) {
            inputRef.current.focus();
        }
    }, [isFilterExpand]);

    return (
        <div className="attendeelist-pane">
            <div
                className={`attendeelist-pane__title ${
                    isFilterExpand ? "expanded" : ""
                }`}
            >
                <div className="attendeelist-pane__title-text">
                    Attendee List
                </div>
                {isFilterExpand ? (
                    <div className="attendeelist-pane__title-search">
                        <div className="attendeelist-pane__title-search--field">
                            <input
                                ref={inputRef}
                                type="search"
                                placeholder="Search attendee"
                                value={searchInput}
                                onChange={(e) => searchAttendee(e.target.value)}
                            />
                            <div
                                className="attendeelist-pane__title-icon"
                                onClick={() =>
                                    setIsFilterExpand(!isFilterExpand)
                                }
                            >
                                <svg className="em-c-icon em-c-icon--small">
                                    <use
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        href={`${icons}#search`}
                                    ></use>
                                </svg>
                            </div>
                        </div>
                        <div className="attendeelist-pane__title-search--status">
                            <div
                                className={
                                    isCheckInFilterd ? "active" : "inactive"
                                }
                                onClick={() =>
                                    setIsCheckInFilterd(!isCheckInFilterd)
                                }
                            >
                                Check-in
                            </div>
                            <div
                                className={
                                    isWalkInFilterd ? "active" : "inactive"
                                }
                                onClick={() =>
                                    setIsWalkInFilterd(!isWalkInFilterd)
                                }
                            >
                                Walk-in
                            </div>
                            <div
                                className={
                                    isNotCheckInFilterd ? "active" : "inactive"
                                }
                                onClick={() =>
                                    setIsNotCheckInFilterd(!isNotCheckInFilterd)
                                }
                            >
                                Not Check-in
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className="attendeelist-pane__title-icon"
                        onClick={() => setIsFilterExpand(!isFilterExpand)}
                    >
                        <svg className="em-c-icon em-c-icon--small">
                            <use
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                href={`${icons}#search`}
                            ></use>
                        </svg>
                    </div>
                )}
            </div>
            <div className="em-l-grid em-l-grid--2up em-l-grid--break-slow">
                {currentAttendees &&
                    currentAttendees.map((attendee: IAttendee) => (
                        <div key={attendee.email} className="em-l-grid__item">
                            <AttendeeCard
                                key={attendee.userId}
                                name={attendee.name}
                                status={attendee.userStatus}
                            />
                        </div>
                    ))}
            </div>
            {currentAttendees && currentAttendees.length > 0 ? (
                <div className="attendeelist-pane__paginationContainer">
                    <div className="em-c-toolbar__item">
                        <ol
                            className="em-c-pagination"
                            role="navigation"
                            aria-labelledby="pagination-label"
                        >
                            <li className="em-c-pagination__item">
                                <a
                                    className={`em-c-pagination__link ${
                                        pageNumber === 1 ? "em-is-disabled" : ""
                                    }`}
                                    onClick={() =>
                                        setPageNumber(pageNumber - 1)
                                    }
                                >
                                    <svg className="em-c-icon em-c-icon--small em-c-pagination__icon">
                                        <use
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            href={`${icons}#caret-left`}
                                        ></use>
                                    </svg>
                                </a>
                            </li>

                            {[...Array(numberOfPage)].map((x, i) => (
                                <li key={i} className="em-c-pagination__item">
                                    <a
                                        className={`em-c-pagination__link ${
                                            i + 1 === pageNumber
                                                ? "em-is-current"
                                                : ""
                                        }`}
                                        onClick={() => setPageNumber(i + 1)}
                                    >
                                        {i + 1}
                                    </a>
                                </li>
                            ))}
                            <li className="em-c-pagination__item">
                                <a
                                    className={`em-c-pagination__link ${
                                        pageNumber === numberOfPage
                                            ? "em-is-disabled"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        setPageNumber(pageNumber + 1)
                                    }
                                >
                                    <svg className="em-c-icon em-c-icon--small em-c-pagination__icon">
                                        <use
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            href={`${icons}#caret-right`}
                                        ></use>
                                    </svg>
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default AttendeeListPane;
