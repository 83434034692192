import React from "react";
import "./VersionCard.scss";

interface IProps {
    version: string;
    date: string;
    details: string;
}

const VersionCard: React.FC<IProps> = ({ version, date, details }) => {
    return (
        <div className="version-card">
            <div className="version-card__body">
                <h1 className="version-card__body__title">Version {version}</h1>
                <h6 className="version-card__body__date">{date}</h6>
                <p className="version-card__body__details">{details}</p>
            </div>
        </div>
    );
};

export default VersionCard;
