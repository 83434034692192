import CheckInService from '../Services/CheckInService'
import {store} from '../Store/store'

class BadgeService {
    guid: string

    constructor(guid:string){
        this.guid = guid
    }

    async getUserByBadge(badgeNumber:string){
        let response = await fetch(`https://hoesql566.na.xom.com/BadgeEventAPI/api/Badge/ID/${badgeNumber}`,
            {
                method:'GET',
                mode:'cors',
                credentials:'include', 
                cache:'no-cache',               
                headers: {
                    'Accept':'application/json'
                } 
            }
        )
        if(response.status !== 200){ throw response }
        let results = await response.json()
        let guid = results.GUID
        const checkInService = new CheckInService("", store.getState().app.currEvent.eventId, guid)
        const request = await checkInService.checkInByBadge()
        return request
    }
}

export default BadgeService