import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendEmail, updateIsSendingEmail } from "../../Store/Actions/setting";
import { clearUserStore } from "../../Store/Actions/user";
import { IUser } from "../../Store/Reducers/user";
import EmailInput from "./EmailInput";
import PreviewData from "../PreviewData/PreviewData";
import Button from "../Button/Button";
import icons from "../../Assets/standard-2.0.2/images/24/em-icons.svg";
import "./EmailModal.scss";

interface IProps {
    careerConnectId: string;
    attendeeType: string;
    closeCallback: Function;
}

const EmailModal: React.FC<IProps> = ({
    careerConnectId,
    attendeeType,
    closeCallback,
}) => {
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.user.eventXUser);
    const event = useSelector((state: any) => state.app.currEvent);
    const [isShowingPreviewData, setIsShowingPreviewData] = useState(false);
    const isSendingEmail = useSelector(
        (state: any) => state.setting.isSendingEmail
    );
    const errorResponse = useSelector((state: any) => state.app.errorResponse);
    const [sender, setSender] = useState(
        "no_reply_eventx_tracker@exxonmobil.com"
    );
    const [subject, setSubject] = useState("");
    const [receivers, setReceivers] = useState(Array<IUser>());
    const [message, setMessage] = useState("");

    const sendEmailCallback = () => {
        dispatch(updateIsSendingEmail(true));
        let emailList = Array();
        receivers.map((user: IUser) => {
            emailList.push(user.email);
        });
        dispatch(
            sendEmail(
                attendeeType,
                careerConnectId,
                subject,
                sender,
                emailList,
                message
            )
        );
    };

    useEffect(() => {
        if (!isSendingEmail && errorResponse.code === "200") {
            closeCallback();
        }
    }, [isSendingEmail, errorResponse]);

    useEffect(() => {
        if (user) {
            setSender(user.email);
        }
    }, [user]);

    useEffect(() => {
        dispatch(clearUserStore());
    }, []);

    return (
        <div className="overlay">
            <div className="email-modal">
                <div className="email-modal__content">
                    <div className="email-modal__content--header">
                        <svg className="em-c-icon em-c-icon--medium">
                                            
                            <use
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                href={`${icons}#envelope-closed`}
                            ></use>
                                        
                        </svg>
                        Email attendee list
                    </div>
                    <div className="email-modal__content--field">
                        <label className="email-modal__content--field__label">
                            To:
                        </label>
                        <EmailInput
                            receiverList={receivers}
                            addEmailCallback={(users: Array<IUser>) =>
                                setReceivers(users)
                            }
                        />
                    </div>
                    <div className="email-modal__content--field">
                        <label className="email-modal__content--field__label">
                            From:
                        </label>
                        <input
                            type="email"
                            className="email-modal__content--field em-c-input"
                            value={sender}
                            disabled
                        />
                    </div>
                    <div className="email-modal__content--field">
                        <label className="email-modal__content--field__label">
                            Subject:
                        </label>
                        <input
                            type="email"
                            className="email-modal__content--field em-c-input"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        />
                    </div>
                    <div
                        className="email-modal__content--file"
                        onClick={() => setIsShowingPreviewData(true)}
                    >
                        <svg className="em-c-icon em-c-icon--medium">
                                            
                            <use
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                href={`${icons}#attachment`}
                            ></use>
                                        
                        </svg>
                        {event.name}.csv
                    </div>
                    <div className="email-modal__content--textarea">
                        <label className="email-modal__content--field__label">
                            Message:
                        </label>
                        <textarea
                            className="email-modal__content--textarea-input "
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>
                </div>
                <div className="email-modal__buttons">
                    <Button
                        name="Send"
                        optionalClass="em-c-btn--primary"
                        hasLoading
                        stopLoading={isSendingEmail}
                        callback={() => sendEmailCallback()}
                    />
                    <Button name="Cancel" callback={closeCallback} />
                </div>
                {isShowingPreviewData ? (
                    <PreviewData
                        attendeeType={attendeeType}
                        careerConnectId={careerConnectId}
                        closeCallback={() => setIsShowingPreviewData(false)}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default EmailModal;
