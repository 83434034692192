export default class ExportService {
    static exportToCSV(filename: string, data: object[]) {
        filename = filename + ".csv";

        if (!data || !data.length) {
            return;
        }

        const csvData = this.prepareData(data);
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    static prepareData(data: any) {
        const separator = ",";
        let newKeys: {
            courseId?: string;
            eventName: string;
            eventDate: string;
            startTime: string;
            eventXContact: string;
            GUID: string;
            name: string;
            email: string;
            LanId: string;
            userStatus: string;
        } = {
            courseId: "Course ID",
            eventName: "Event Name",
            eventDate: "Event Start Date",
            startTime: "Event Start Time (UTC)",
            eventXContact: "EventX Contact",
            GUID: "GUID",
            name: "Name",
            email: "Email",
            LanId: "LAN ID",
            userStatus: "Status",
        };
        if (data[0].courseId === "") {
            delete newKeys.courseId;
        }
        const csvData =
            Object.values(newKeys).join(separator) +
            "\n" +
            data
                .map((row: any) => {
                    return Object.keys(newKeys)
                        .map((k) => {
                            let cell = row[k];
                            if (cell.search(/("|,|\n)/g) >= 0) {
                                cell = `"${cell}"`;
                            }
                            if (k === "eventDate" || k === "startTime") {
                                cell = `"=""${cell}"""`;
                            } else if (k === "userStatus") {
                                cell = cell.replace(/-/g, " ").toUpperCase();
                            } else if (k === "GUID") {
                                cell = `"=""${cell}"""`;
                            }
                            return cell;
                        })
                        .join(separator);
                })
                .join("\n");

        return csvData;
    }
}
