import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { badgeQuestionUpdate } from "../../Store/Actions/question";
import Question from "../../Components/Questions/Question";

const BadgeQuestion: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const event = useSelector((state: any) => state.app.currEvent);

  const questionCallback = (value: string) => {
    let payload = false;
    if (value.toLowerCase() === "yes") {
      payload = true;
      dispatch(badgeQuestionUpdate(payload));
      navigate(`/events/${event.eventId}/checkin/badge`);
    } else {
      dispatch(badgeQuestionUpdate(payload));
      navigate(`/events/${event.eventId}/checkin/email`);
    }
  };

  return (
    <Question
      question="Will you be using a badge reader?"
      firstDetail="Plug in a badge reader to your computer's USB port and let attendees use their ExxonMobil
            badge to check in to your EventX event."
      secondDetail="No badge? No problem. If your attendees don't have a badge, they can check in using their
            name or email address."
      optionalClass="badge"
      yesCallback={questionCallback}
      noCallback={questionCallback}
    />
  );
};

export default BadgeQuestion;
