import React from "react";
import icon from "../../Assets/standard-2.0.2/images/icon-spinner.svg";
import "./LoadingIndicator.scss";

interface IProps {
    optionalClass?: string;
}

const LoadingIndicator: React.FC<IProps> = ({ optionalClass }) => {
    return (
        <div className={`${optionalClass} loading-indicator`}>
            <img className="loading-indicator_img" src={icon} alt="Loading" />
        </div>
    );
};

export default LoadingIndicator;
