import {store} from '../Store/store'

class AdminService {
    static async getAdminsAsync(eventId:string, signal:AbortSignal){
        let response = await fetch(`${process.env.REACT_APP_EVENTX_TRACKER_API_URL}/EventAdminGet?code=${process.env.REACT_APP_API_CODE}&eventId=${eventId}`,
            {
                method:'GET',
                mode:'cors',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer ' + store.getState().user.eventXTrackerToken,
                }
            }
        )
        if(response.status !== 200){ throw response }
        return await response.json()
    }

    static async addAdminAsync(data:any, signal:AbortSignal){
        let response = await fetch(`${process.env.REACT_APP_EVENTX_TRACKER_API_URL}/EventAdminAdd?code=${process.env.REACT_APP_API_CODE}`,
            {
                method:'POST',
                mode:'cors',
                cache: 'no-cache',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer ' + store.getState().user.eventXTrackerToken,
                },
                body: JSON.stringify(data)
            }
        )
        if(response.status !== 200){ throw response }
        return await response.json()
    }

    
    static async removeAdminAsync(data:any, signal:AbortSignal){
        let response = await fetch(`${process.env.REACT_APP_EVENTX_TRACKER_API_URL}/EventAdminRemove?code=${process.env.REACT_APP_API_CODE}&userId=${data.userId}&eventId=${data.eventId}`,
            {
                method:'DELETE',
                mode:'cors',
                cache: 'no-cache',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer ' + store.getState().user.eventXTrackerToken,
                },
            }
        )
        if(response.status !== 200){ throw response }
        return await response.text()
    }
}

export default AdminService