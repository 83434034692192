import moment from 'moment'
import {versions} from '../../Utilities/versions'
import {IEvent, ITag} from "./event"

export interface IResponse {
    code: any,
    message: string
}
interface IState {
    currMonth: string,
    currYear: number,
    currEvent: IEvent,
    eventsOfCurrMonth: Array<IEvent>,
    tagsStatus: Array<ITag>,
    isHomeBtnActive: boolean,
    errorResponse: IResponse,
    isConfirmCheckedIn: boolean,
    isLoadingCheckedIn: boolean,
    isLoading: boolean,
    isLoadingCurrEvent: boolean,
    filterType: string,
    versions: Array<Object>,
    videoTutorialURL: string,
    docTutorialURL: string
}

export const initState:IState = {
    currMonth: "",
    currYear: moment().year(),
    currEvent: {eventId: "", name: "", location: "", startTime: new Date(), endTime: new Date(), participants: 0, tags: [], allowWalkIn:false, allowExceedCapacity:false, checkedInAttendees:0, walkedInAttendees:0, isExist:true, understandDisclaimer:false},
    eventsOfCurrMonth: [],
    tagsStatus: [],
    isHomeBtnActive: false,
    errorResponse: {code: "", message: ""},
    isConfirmCheckedIn: false,
    isLoadingCheckedIn: false,
    isLoadingCurrEvent: false,
    isLoading: false,
    filterType: 'All',
    versions: versions,
    videoTutorialURL: '',
    docTutorialURL: ''
}

export default (state:IState = initState, action:any) => {
    switch (action.type) {   
        case 'UPDATE_TUTORIAL_URL':
            return {...state, videoTutorialURL:action.payload.video, docTutorialURL:action.payload.doc}
        case 'UPDATE_IS_HOME_BUTTON_ACTIVE':
            return {...state, isHomeBtnActive:action.payload}
        case 'UPDATE_EVENTS_OF_CURR_MONTH':
            return {...state, eventsOfCurrMonth:action.payload}
        case 'UPDATE_CURR_YEAR':
            return {...state, currYear:action.payload}
        case 'UPDATE_CURR_EVENT':
            return {...state, currEvent:action.payload}
        case 'UPDATE_CURR_MONTH':
            return {...state, currMonth:action.payload}
        case 'UPDATE_TAGS_STATUS':
            return {...state, tagsStatus:action.payload}
        case 'UPDATE_FILTER_TYPE':
            return {...state, filterType:action.payload}
        case 'UPDATE_ERROR_RESPONSE':
            return {...state, errorResponse:action.payload}
        case 'UPDATE_IS_CONFIRM_CHECKED_IN':
            return {...state, isConfirmCheckedIn:action.payload} 
        case 'UPDATE_IS_LOADING_CHECKED_IN':
            return {...state, isLoadingCheckedIn:action.payload} 
        case 'UPDATE_IS_LOADING':
            return {...state, isLoading:action.payload}
        case 'UPDATE_IS_LOADING_CURR_EVENT':
            return {...state, isLoadingCurrEvent:action.payload}
        case 'CLEAR_STORE':    
            return action.payload === null ? initState : {...initState, currYear:action.payload}
        case 'CLEAR_ERROR_RESPONSE_STORE':
            return {...state, errorResponse:{code: "", message: ""}}
        default:
            return state
    }
}