import React from "react";
import { useSelector } from "react-redux";
import CircularProgressBar from "../CircularProgressBar/CircularProgressBar";
import AttendeeListPane from "../AttendeeListPane/AttendeeListPane";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import "./AttendanceSetting.scss";

const AttendanceSetting: React.FC = () => {
  const attendeeList = useSelector((state: any) => state.attendee.attendeeList);
  const isLoadingCurrEvent = useSelector(
    (state: any) => state.app.isLoadingCurrEvent
  );
  const isLoadingAttendeeList = useSelector(
    (state: any) => state.attendee.isLoadingAttendeeList
  );

  return (
    <div className="attendance-setting">
      <div className="em-l-container">
        {isLoadingCurrEvent || isLoadingAttendeeList ? (
          <LoadingIndicator optionalClass="attendance-setting__loading" />
        ) : (
          <div className="em-l-grid em-l-grid--2up em-l-grid--break-fast">
            <div className="em-l-grid__item">
              <div className="attendance-setting__left-pane">
                <CircularProgressBar
                  checkedInNumber={
                    attendeeList
                      ? attendeeList.filter(
                          (attendee: any) =>
                            attendee.userStatus === "checked-in"
                        ).length
                      : 0
                  }
                  walkedInNumber={
                    attendeeList
                      ? attendeeList.filter(
                          (attendee: any) => attendee.userStatus === "walked-in"
                        ).length
                      : 0
                  }
                  total={attendeeList ? attendeeList.length : 0}
                />
              </div>
            </div>
            <div className="em-l-grid__item">
              <div className="attendance-setting__right-pane">
                {attendeeList.length === 0 ? (
                  <div className="attendance-setting__right-pane__msgtext">
                    No one registered to this event yet.
                  </div>
                ) : (
                  <AttendeeListPane />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendanceSetting;
