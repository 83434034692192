import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSASToken } from "../../Store/Actions/app";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import icons from "../../Assets/standard-2.0.2/images/24/em-icons.svg";
import poster from "../../Assets/images/video-poster.jpg";
import "./TutorialModal.scss";

interface IProps {
    closeCallback: Function;
}

const TutorialModal: React.FC<IProps> = ({ closeCallback }) => {
    const dispatch = useDispatch();
    const videoTutorialURL = useSelector(
        (state: any) => state.app.videoTutorialURL
    );
    const docTutorialURL = useSelector(
        (state: any) => state.app.docTutorialURL
    );

    useEffect(() => {
        dispatch(getSASToken());
    }, []);

    return (
        <div className="overlay">
            <div className="tutorial-modal">
                <div
                    onClick={() => closeCallback()}
                    className="tutorial-modal__close"
                >
                    <svg className="em-c-icon em-c-icon--small em-c-pagination__icon">
                        <use
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            href={`${icons}#x-filled`}
                        />
                    </svg>
                </div>
                {videoTutorialURL === "" || docTutorialURL === "" ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <video
                            src={videoTutorialURL}
                            controls
                            preload="auto"
                            poster={poster}
                        />
                        <div className="tutorial-modal__link">
                            Documentation:
                            <a href={docTutorialURL}>
                                EventX Tracker Tutorial.docx
                            </a>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default TutorialModal;
