import { store } from "../store";
import { IAttendee } from "../Reducers/attendee";
import { updateErrorResponse } from "../Actions/app";
import EventService from "../../Services/EventService";
import ExportService from "../../Services/ExportService";
import EmailService from "../../Services/EmailService";
import { ISetting } from "../Reducers/setting";
import moment from "moment-timezone";

export const updateSetting = (settingName: string) => {
    return async (dispatch: any) => {
        let data = {
            eventId: store.getState().app.currEvent.eventId,
            allowWalkIn: false,
            allowExceedCapacity: false,
        };
        let settingList = [...store.getState().setting.settingList];
        let newSetting = settingList.find(
            (x: ISetting) => x.name === settingName
        );
        let exceedCapacity = settingList.find(
            (x: ISetting) => x.name === "Allow Exceed Capacity"
        );
        if (newSetting) {
            newSetting.isActive = !newSetting.isActive;
            if (settingName === "Allow Walk In") {
                if (newSetting.isActive) {
                    exceedCapacity.isActive = false;
                    exceedCapacity.isDisable = false;
                    data.allowWalkIn = true;
                } else {
                    exceedCapacity.isActive = false;
                    exceedCapacity.isDisable = true;
                    data.allowExceedCapacity = false;
                }
            } else {
                if (newSetting.isActive) {
                    data.allowWalkIn = true;
                    data.allowExceedCapacity = true;
                } else {
                    data.allowWalkIn = true;
                    data.allowExceedCapacity = false;
                    exceedCapacity.isActive = false;
                }
            }
        }
        dispatch({ type: "UPDATE_EVENT_SETTING", payload: settingList });
        const request = EventService.updateAllowWalkIn(data);
        request
            .then((response: any) => {})
            .catch(async (error: any) => {
                if (error.status === 401) {
                    let resMessage = await error.text();
                    if (
                        resMessage ===
                        "You are not authorized to access this function."
                    ) {
                        dispatch(
                            updateErrorResponse({
                                code: "401",
                                message: resMessage,
                            })
                        );
                    } else {
                        dispatch(
                            updateErrorResponse({
                                code: "401",
                                message: "Session Expired",
                            })
                        );
                    }
                } else {
                    let resMessage = await error.text();
                    dispatch(
                        updateErrorResponse({
                            code: error.status,
                            message: resMessage || error.statusText,
                        })
                    );
                }
            });
    };
};

export const exportCSV = (attendeeType: string, careerConnectId: string) => {
    return (dispatch: any) => {
        const event = store.getState().app.currEvent;
        let attendees = prepareDataObject(attendeeType, careerConnectId);
        if (attendees.length === 0) {
            dispatch(
                updateErrorResponse({
                    code: "",
                    message: `This event has no ${
                        attendeeType === "all"
                            ? ""
                            : attendeeType.replace(/-/g, " ")
                    } attendees.`,
                })
            );
        } else {
            ExportService.exportToCSV(
                `${event.name}_${attendeeType}`,
                attendees
            );
        }
    };
};

export const sendEmail = (
    attendeeType: string,
    careerConnectId: string,
    subject: string,
    sender: string,
    receiver: Array<string>,
    message: string
) => {
    return (dispatch: any) => {
        const event = store.getState().app.currEvent;
        let attendees = prepareDataObject(attendeeType, careerConnectId);
        if (attendees.length === 0) {
            dispatch(
                updateErrorResponse({
                    code: "",
                    message: `This event has no ${
                        attendeeType === "all"
                            ? ""
                            : attendeeType.replace(/-/g, " ")
                    } attendees.`,
                })
            );
        } else {
            let request = EmailService.sendMail(
                attendees,
                event.name,
                subject,
                sender,
                receiver,
                message
            );
            request
                .then((response: any) => {
                    dispatch(updateIsSendingEmail(false));
                    dispatch(
                        updateErrorResponse({
                            code: "200",
                            message: "Email has been sent.",
                        })
                    );
                })
                .catch((error: any) => {
                    dispatch(updateIsSendingEmail(false));
                    dispatch(
                        updateErrorResponse({
                            code: "400",
                            message: "Something wrong, please try again.",
                        })
                    );
                });
        }
    };
};

export const sendEmailToCC = (
    attendeeType: string,
    careerConnectId: string,
    sender: string
) => {
    return (dispatch: any) => {
        const event = store.getState().app.currEvent;
        let attendees = prepareDataObject(attendeeType, careerConnectId);
        if (attendees.length === 0) {
            dispatch(
                updateErrorResponse({
                    code: "",
                    message: `This event has no ${
                        attendeeType === "all"
                            ? ""
                            : attendeeType.replace(/-/g, " ")
                    } attendees.`,
                })
            );
        } else {
            let request = EmailService.sendMail(
                attendees,
                event.name,
                "Upload Attendees into Career Connect",
                sender,
                [`${process.env.REACT_APP_CAREERCONNECT_EMAIL}`],
                "",
                [sender]
            );
            request
                .then((response: any) => {
                    dispatch(updateIsSendingEmail(false));
                    dispatch(
                        updateErrorResponse({
                            code: "200",
                            message:
                                "Email has been sent to Career Connect Support.",
                        })
                    );
                })
                .catch((error: any) => {
                    dispatch(updateIsSendingEmail(false));
                    dispatch(
                        updateErrorResponse({
                            code: "404",
                            message: "Missing some information.",
                        })
                    );
                });
        }
    };
};

export const prepareDataObject = (
    attendeeType: string,
    careerConnectId: string
) => {
    const event = store.getState().app.currEvent;
    let attendees = [...store.getState().attendee.attendeeList];
    attendees = attendees.filter((attendee: IAttendee) => {
        if (attendeeType === "attended") {
            return (
                attendee.userStatus === "checked-in" ||
                attendee.userStatus === "walked-in"
            );
        } else if (attendeeType === "all") {
            return true;
        } else {
            return attendee.userStatus === attendeeType;
        }
    });
    attendees.map((attendee: any) => {
        attendee.eventName = event.name;
        attendee.eventDate = moment(event.startTime).utc().format("L");
        attendee.startTime = moment(event.startTime).utc().format("hh:mm A");
        attendee.courseId = careerConnectId;
        attendee.eventXContact = event.responsible;
    });
    return attendees;
};

export const updateIsSendingEmail = (value: boolean) => {
    return (dispatch: any) => {
        dispatch({ type: "UPDATE_IS_SENDING_EMAIL", payload: value });
    };
};
