import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { clearErrorResponseStore } from "../../Store/Actions/app";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getEventInfo } from "../../Store/Actions/event";
import { updateIsLoadingCurrEvent } from "../../Store/Actions/app";
import { initState } from "../../Store/Reducers/app";
import LoadingIndicator from "../../Components/LoadingIndicator/LoadingIndicator";
import BadgeReader from "../../Components/BadgeReader/BadgeReader";
import UserPicker from "../../Components/UserPicker/UserPicker";
import EventHeader from "../../Components/EventHeader/EventHeader";
import ErrorModal from "../../Components/ErrorModal/ErrorModal";
import "./CheckInPage.scss";

const CheckInPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { eventId } = useParams<any>();
  const [isShowingError, setIsShowingError] = useState(false);
  const isLoadingCurrEvent = useSelector(
    (state: any) => state.app.isLoadingCurrEvent
  );
  const errorResponse = useSelector((state: any) => state.app.errorResponse);
  const eventXTrackerToken = useSelector(
    (state: any) => state.user.eventXTrackerToken
  );
  const event = useSelector((state: any) => state.app.currEvent);

  const processCallback = () => {
    if (
      errorResponse.message === "Something is missing. Please try again." ||
      errorResponse.message === "Session Expired"
    ) {
    } else if (
      errorResponse.message ===
        "You are not authorized to access this function." ||
      errorResponse.message === "Event is already finished"
    ) {
      navigate("/events");
    }
    setIsShowingError(false);
    dispatch(clearErrorResponseStore());
  };

  useEffect(() => {
    if (errorResponse.message !== "") {
      setIsShowingError(true);
    }
  }, [errorResponse]);

  useEffect(() => {
    if (event === initState.currEvent && eventXTrackerToken !== "") {
      dispatch(updateIsLoadingCurrEvent(true));
      dispatch(getEventInfo(`${eventId}`, "checkin"));
    }
  }, [eventXTrackerToken]);

  return (
    <div className="checkin-page">
      {isLoadingCurrEvent ? (
        <LoadingIndicator />
      ) : (
        <div className="checkin-page__container">
          <EventHeader />
          <Routes>
            <Route path="/badge" element={<BadgeReader />} />
            <Route path="/email" element={<UserPicker />} />
          </Routes>
          {isShowingError ? (
            <ErrorModal
              question={errorResponse.message}
              subQuestion={
                errorResponse.message === "User has already checked in" &&
                location.pathname === `/events/${event.eventId}/checkin/email`
                  ? "Did you select the right user?"
                  : ""
              }
              firstBtnName={
                (errorResponse.message === "User has already checked in" &&
                  location.pathname ===
                    `/events/${event.eventId}/checkin/email`) ||
                errorResponse.message ===
                  "This event was set to be 'Not allow walk in' please contact event organizer."
                  ? "Back"
                  : (errorResponse.message === "User has already checked in" &&
                      location.pathname ===
                        `/events/${event.eventId}/checkin/badge`) ||
                    errorResponse.message ===
                      "This event was set to be 'Not allow walk in' please contact event organizer."
                  ? "Scan another badge"
                  : errorResponse.message ===
                    "Something is missing. Please try again."
                  ? "Try again"
                  : errorResponse.message === "Session Expired"
                  ? "Refresh"
                  : errorResponse.message ===
                      "You are not authorized to access this function." ||
                    errorResponse.message === "Event is already finished"
                  ? "Home"
                  : "Back to Process"
              }
              firstBtnCallback={processCallback}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default CheckInPage;
