import {store} from '../store'
import {updateErrorResponse} from '../Actions/app'
import EventService from '../../Services/EventService'

export const walkInQuestionUpdate = (value:boolean) => {
    let data = {
        eventId: store.getState().app.currEvent.eventId,
        allowWalkIn: value,
        allowExceedCapacity: store.getState().question.allowExceedCapacity === null ? false : store.getState().question.allowExceedCapacity
    }
    const request = EventService.updateAllowWalkIn(data)     
    return (dispatch:any) => {
        request.then((response:any) => {})
        .catch(async(error:any) => {
            if(error.status === 401){
                let resMessage = await error.text()
                if(resMessage === "You are not authorized to access this function."){
                    dispatch(updateErrorResponse({code:"401", message:resMessage}))   
                } else {
                    dispatch(updateErrorResponse({code:"401", message:"Session Expired"}))   
                }   
            } else {
                let resMessage = await error.text()
                dispatch(updateErrorResponse({code:error.status, message:resMessage || error.statusText}))
            }
        })
        if(!value) dispatch({type:'EXCEED_CAPACITY_UPDATE', payload:false})
        dispatch({type:'WALK_IN_QUESTION_UPDATE', payload:value})
    }
}

export const exceedCapacityUpdate = (value:boolean) => {
    return (dispatch:any) => {
        dispatch({type:'EXCEED_CAPACITY_UPDATE', payload:value})
    }
}

export const badgeQuestionUpdate = (value:boolean) => {
    return (dispatch:any) => {
        dispatch({type:'BADGE_QUESTION_UPDATE', payload:value})
    }
}

export const backToBadgeUpdate = (value:boolean) => {
    return (dispatch:any) => {
        dispatch({type:'BACK_TO_BADGE_UPDATE', payload:value})
    }
}