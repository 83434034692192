import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "../IconButton/IconButton";
import "./EventHeader.scss";

const EventHeader: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isVisible, setVisible] = useState(false);
  const lastLocation = location.pathname;
  const event = useSelector((state: any) => state.app.currEvent);
  const isHomeBtnActive = useSelector(
    (state: any) => state.app.isHomeBtnActive
  );

  const homeCallback = () => {
    if (
      location.pathname !== "/" &&
      location.pathname !== "/checkin-confirmation"
    ) {
      navigate("/events");
    }
  };

  const prevCallback = () => {
    if (lastLocation !== null) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    location.pathname === "/" || location.pathname === "/checkin-confirmation"
      ? setVisible(false)
      : setVisible(true);
  }, [location]);

  return (
    <div>
      {isVisible ? (
        <div className="event-header">
          <div className="event-header__button">
            {!location.pathname.includes("/questions/") && (
              <div className="em-c-tooltip em-c-tooltip--icon em-c-tooltip--light em-js-tooltip">
                <IconButton
                  icon="chevron-left"
                  optionalClass="btn-icon"
                  callback={prevCallback}
                />
                <span className="em-c-tooltip__content">Previous Page</span>
              </div>
            )}
            {isHomeBtnActive || location.pathname.includes("/checkin/") ? (
              <div className="em-c-tooltip em-c-tooltip--icon em-c-tooltip--light em-js-tooltip">
                <IconButton
                  icon="home"
                  optionalClass="btn-icon em-u-margin-left-double"
                  callback={homeCallback}
                />
                <span className="em-c-tooltip__content">Home</span>
              </div>
            ) : null}
          </div>
          <div className="event-header__title">{event ? event.name : ""}</div>
          <div className="event-header__next-button"></div>
        </div>
      ) : null}
    </div>
  );
};

export default EventHeader;
