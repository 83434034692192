import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Routes,
  Route,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import { duplicateEvent } from "../../Store/Actions/event";
import {
  clearErrorResponseStore,
  updateIsLoadingCurrEvent,
} from "../../Store/Actions/app";
import {
  getAttendeeList,
  updateIsLoadingAttendeeList,
} from "../../Store/Actions/attendee";
import {
  getAdminList,
  updateIsLoadingAdminList,
} from "../../Store/Actions/admin";
import { initState } from "../../Store/Reducers/app";
import SettingSubHeader from "../../Components/SettingSubHeader/SettingSubHeader";
import AttendanceSetting from "../../Components/AttendanceSetting/AttendanceSetting";
import LoadingIndicator from "../../Components/LoadingIndicator/LoadingIndicator";
import AccessSetting from "../../Components/AccessSetting/AccessSetting";
import ExportSetting from "../../Components/ExportSetting/ExportSetting";
import OptionsSetting from "../../Components/OptionsSetting/OptionsSetting";
import ErrorModal from "../../Components/ErrorModal/ErrorModal";
import moment from "moment";
import icons from "../../Assets/standard-2.0.2/images/24/em-icons.svg";

import "./SettingPage.scss";

const SettingPage: React.FC = () => {
  const { eventId } = useParams<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShowingError, setIsShowingError] = useState(false);
  const event = useSelector((state: any) => state.app.currEvent);
  const isLoadingCurrEvent = useSelector(
    (state: any) => state.app.isLoadingCurrEvent
  );
  const errorResponse = useSelector((state: any) => state.app.errorResponse);
  const eventXTrackerToken = useSelector(
    (state: any) => state.user.eventXTrackerToken
  );

  const processCallback = () => {
    if (
      errorResponse.message === "Something is missing. Please try again." ||
      errorResponse.message === "Session Expired"
    ) {
    } else if (
      errorResponse.message ===
        "You are not authorized to access this function." ||
      errorResponse.message === "Event is already finished"
    ) {
      navigate("/events");
    }
    setIsShowingError(false);
    dispatch(clearErrorResponseStore());
  };

  useEffect(() => {
    if (eventXTrackerToken !== "") {
      if (event === initState.currEvent) {
        dispatch(updateIsLoadingCurrEvent(true));
        dispatch(duplicateEvent(`${eventId}`, "setting"));
      } else {
        dispatch(getAttendeeList(`${eventId}`));
        dispatch(getAdminList(`${eventId}`));
      }
      dispatch(updateIsLoadingAttendeeList(true));
      dispatch(updateIsLoadingAdminList(true));
    }
  }, [eventXTrackerToken]);

  useEffect(() => {
    if (errorResponse.message !== "") {
      setIsShowingError(true);
    }
  }, [errorResponse]);

  return (
    <div className="setting-page">
      {isLoadingCurrEvent ? (
        <div className="setting-page__loading">
          <LoadingIndicator />
        </div>
      ) : (
        <div className="setting-page__container">
          <SettingSubHeader />
          {!event.isExist ? (
            <div className="em-l-container">
              <div className="em-c-alert em-c-alert--warning" role="alert">
                <svg className="em-c-icon em-c-icon--small em-c-alert__icon">
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    href={`${icons}#warning`}
                  ></use>
                </svg>
                <div className="em-c-alert__body">
                  This event cannot be updated as it is already cancelled.
                </div>
              </div>
            </div>
          ) : moment(event.endTime).add(1, "days").isBefore(moment()) &&
            event.isExist ? (
            <div className="em-l-container">
              <div className="em-c-alert em-c-alert--warning" role="alert">
                <svg className="em-c-icon em-c-icon--small em-c-alert__icon">
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    href={`${icons}#warning`}
                  ></use>
                </svg>
                <div className="em-c-alert__body">
                  This event cannot be updated as it is already completed.
                </div>
              </div>
            </div>
          ) : null}
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  to={`/events/${eventId}/setting/attendance`}
                  replace
                />
              }
            />
            <Route path="/attendance" element={<AttendanceSetting />} />
            <Route path="/access" element={<AccessSetting />} />
            <Route path="/export" element={<ExportSetting />} />
            <Route path="/options" element={<OptionsSetting />} />
          </Routes>
        </div>
      )}
      {isShowingError ? (
        <ErrorModal
          question={errorResponse.message}
          firstBtnName={
            errorResponse.message === "Something is missing. Please try again."
              ? "Try again"
              : errorResponse.message === "Session Expired"
              ? "Refresh"
              : errorResponse.message ===
                  "You are not authorized to access this function." ||
                errorResponse.message === "Event is already finished"
              ? "Home"
              : "Close"
          }
          firstBtnCallback={processCallback}
        />
      ) : null}
    </div>
  );
};

export default SettingPage;
