import React from 'react'
import {CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import './CircularProgressBar.scss'

interface IProps {
    checkedInNumber: number,
    walkedInNumber: number,
    total: number
}

const CircularProgressBar:React.FC<IProps> = ({checkedInNumber, walkedInNumber, total}) => {   
    return (
        <div className="circular-progress-bar">
            <div className="circular-progress-bar__bar">
                <CircularProgressbarWithChildren
                    value={(checkedInNumber + walkedInNumber)/total*100} 
                    strokeWidth={10}
                    styles={buildStyles({ 
                        rotation: 0,
                        strokeLinecap: 'butt',
                        pathTransitionDuration: 0.5,
                        textColor: '#393939',
                        trailColor: '#D6D6D6',
                        pathColor:  total === 0 ? '#D6D6D6' : '#B4D405' //walk-in
                    })}
                >
                    <CircularProgressbarWithChildren
                        value={checkedInNumber/total*100} 
                        strokeWidth={10}
                        styles={buildStyles({ 
                            rotation: 0,
                            strokeLinecap: 'butt',
                            pathTransitionDuration: 0.5,
                            textColor: '#393939',
                            trailColor: 'transparent',
                            pathColor: total === 0 ? '#D6D6D6' : '#3FAD3F' //check-in
                        })}
                    >
                        <div className="circular-progress-bar__number">
                            <div className="circular-progress-bar__number--first">{checkedInNumber + walkedInNumber}</div> 
                            <div className="circular-progress-bar__number--total">/ {total}</div> 
                        </div>
                    </CircularProgressbarWithChildren>
                </CircularProgressbarWithChildren>
            </div>
            <div className="circular-progress-bar__detail">
                <span className="circular-progress-bar__detail--number">{checkedInNumber}</span>
                <span className="circular-progress-bar__status checked-in"/>
                <span className="circular-progress-bar__detail--text">Attendees Check-in</span>
            </div>
            <div className="circular-progress-bar__detail">
                <span className="circular-progress-bar__detail--number">{walkedInNumber}</span>
                <span className="circular-progress-bar__status walked-in"/>
                <span className="circular-progress-bar__detail--text">Attendees Walk-in</span>
            </div>
            <div className="circular-progress-bar__detail">
                <span className="circular-progress-bar__detail--number">{total - (checkedInNumber + walkedInNumber)}</span>
                <span className="circular-progress-bar__status not-checked-in"/>
                <span className="circular-progress-bar__detail--text">Attendees Not Check-in</span>
            </div>
        </div>
    )
}

export default CircularProgressBar