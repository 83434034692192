import React from 'react'
import Button from '../Button/Button'
import './ErrorModal.scss'

interface IProps {
    question: string, 
    subQuestion?: string,
    firstBtnCallback: Function, 
    secondBtnCallback?: Function,
    firstBtnName?: string,
    secondBtnName?: string
}

const ErrorModal: React.FC<IProps> = ({question, subQuestion, firstBtnCallback, secondBtnCallback, firstBtnName, secondBtnName}) => {
    return (
        <div className="overlay">
            <div className="error-modal">
                <div className="error-modal__question">
                    {question}
                </div>
                {subQuestion !== null || subQuestion !== '' ? 
                <div className="error-modal__sub-question">
                    {subQuestion}
                </div> : null}
                <div className="error-modal__buttons">
                    <Button name={firstBtnName ? firstBtnName : "Yes"} callback={firstBtnCallback} optionalClass={"em-c-btn--primary em-u-margin-right"} />
                    {secondBtnName && secondBtnCallback !== undefined ?
                    <Button name={secondBtnName ? secondBtnName : "No"} callback={secondBtnCallback} />
                    : null}
                </div>
            </div>
        </div>
    )
}

export default ErrorModal