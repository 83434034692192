import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateCurrYear, updateIsLoading } from "../../Store/Actions/app";
import "./YearPicker.scss";
import moment from "moment";

const YearPicker: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currYear = useSelector((state: any) => state.app.currYear);
    const numOfYearOptions = 8;
    const listOfOption = Array(numOfYearOptions).fill(null);

    const pathChangeCallback = (year: any, month: any) => {
        navigate(
            `/events?year=${year}${
                month !== undefined ? `&month=${month}` : ""
            }`
        );
    };

    const setYear = (year: number) => {
        let month =
            moment().format("YYYY") === year.toString()
                ? moment().format("MMM")
                : undefined;
        navigate(
            `/events?year=${year}${
                month !== undefined ? `&month=${month}` : ""
            }`
        );
        dispatch(updateCurrYear(year.toString(), month, pathChangeCallback));
        dispatch(updateIsLoading(true));
    };

    return (
        <select
            className="year-picker em-c-select em-u-clickable"
            value={currYear}
            onChange={(e) => setYear(parseInt(e.target.value))}
        >
            {listOfOption.map((num: number, index: number) =>
                currYear - numOfYearOptions / 2 + index >= 2019 ? (
                    <option
                        key={index}
                        value={currYear - numOfYearOptions / 2 + index}
                    >
                        {currYear - numOfYearOptions / 2 + index}
                    </option>
                ) : null
            )}
        </select>
    );
};

export default YearPicker;
