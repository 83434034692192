import AdminService from '../../Services/AdminService'
import {IAdmin} from '../Reducers/admin'
import {store} from '../store'
import {updateErrorResponse} from '../Actions/app'

let controller:AbortController
let signal:AbortSignal

export const getAdminList = (eventId:string) => {
    if (controller !== undefined) {
        controller.abort()
    }
    controller = new AbortController()
    signal = controller.signal
    const request = AdminService.getAdminsAsync(eventId, signal)
    return (dispatch:any) => {
        request.then((adminList:any) => {
            dispatch(updateAdminList(adminList))
            dispatch(updateIsLoadingAdminList(false))
        }).catch(async(error) => {
            if(error.status === 401){
                let resMessage = await error.text()
                if(resMessage === "You are not authorized to access this function."){
                    dispatch(updateErrorResponse({code:"401", message:resMessage}))   
                } else {
                    dispatch(updateErrorResponse({code:"401", message:"Session Expired"}))   
                }
            } else if (error.name === 'AbortError'){
                console.error("The user aborted a request.")                  
            }
        })
    }
}

export const addAdmin = (userId:string, userType:string) => {
    if (controller !== undefined) {
        controller.abort()
    }
    controller = new AbortController()
    signal = controller.signal
    let data = {
        userId, 
        eventId: store.getState().app.currEvent.eventId,
        userType
    }
    return (dispatch:any) => {
        const request = AdminService.addAdminAsync(data, signal)
        request.then((response:IAdmin) => {
            let adminList = [...store.getState().admin.adminList]
            adminList.push(response)
            dispatch(updateAdminList(adminList))
            dispatch(updateIsLoadingAdminList(false))
        }).catch(async(error) => {
            if(error.status === 401){
                let resMessage = await error.text()
                if(resMessage === "You are not authorized to access this function."){
                    dispatch(updateErrorResponse({code:"401", message:resMessage}))   
                } else {
                    dispatch(updateErrorResponse({code:"401", message:"Session Expired"}))   
                }   
            } else if (error.name === 'AbortError'){
                console.error("The user aborted a request.")                  
            }
        })
    }
}

export const removeAdmin = (userId:string) => {
    if (controller !== undefined) {
        controller.abort()
    }
    controller = new AbortController()
    signal = controller.signal
    let data = {
        userId, 
        eventId: store.getState().app.currEvent.eventId
    }
    return (dispatch:any) => {
        const request = AdminService.removeAdminAsync(data, signal)
        request.then((response:any) => {   
            let adminList = store.getState().admin.adminList
            adminList = adminList.filter((admin:IAdmin) => admin.userId !== userId)
            dispatch(updateAdminList(adminList))
            dispatch(updateIsLoadingAdminList(false))
        }).catch(async(error) => {
            if(error.status === 401){
                let resMessage = await error.text()
                if(resMessage === "You are not authorized to access this function."){
                    dispatch(updateErrorResponse({code:"401", message:resMessage}))   
                } else {
                    dispatch(updateErrorResponse({code:"401", message:"Session Expired"}))   
                }   
            } else if (error.name === 'AbortError'){
                console.error("The user aborted a request.")                  
            } 
        })
    }
}

export const updateAdminList = (adminList:Array<IAdmin>) => {
    return (dispatch:any) => {
        dispatch({type:'UPDATE_ADMIN_LIST', payload:adminList})
    }
}

export const updateIsLoadingAdminList = (value:boolean) => {
    return (dispatch:any) => {
        dispatch({type:'UPDATE_IS_LOADING_ADMIN_LIST', payload:value})
    }
}