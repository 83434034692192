import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IAdmin } from "../../Store/Reducers/admin";
import { removeAdmin } from "../../Store/Actions/admin";
import { updateUserList, clearUserStore } from "../../Store/Actions/user";
import { addAdmin, updateIsLoadingAdminList } from "../../Store/Actions/admin";
import { IUser } from "../../Store/Reducers/user";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import UserCard from "../UserCard/UserCard";
import Button from "../Button/Button";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import moment from "moment";
import icons from "../../Assets/standard-2.0.2/images/24/em-icons.svg";
import "./AccessSetting.scss";

const AccessSetting: React.FC = () => {
  const dispatch = useDispatch();
  const [userType, setUserType] = useState("User");
  const [isLoading, setIsLoading] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const event = useSelector((state: any) => state.app.currEvent);
  const adminList = useSelector((state: any) => state.admin.adminList);
  const isLoadingAdminList = useSelector(
    (state: any) => state.admin.isLoadingAdminList
  );
  const userList = useSelector((state: any) => state.user.userList);
  const isUserNotFound = useSelector((state: any) => state.user.isUserNotFound);

  const removeAdminCallback = (admin: IAdmin) => {
    dispatch(updateIsLoadingAdminList(true));
    dispatch(removeAdmin(admin.userId));
  };

  const emailInputCallback = (value: string) => {
    dispatch(clearUserStore());
    setEmailInput(value);
    if (value.length >= 3) {
      setIsLoading(true);
      dispatch(updateUserList(value, userType));
    }
  };

  const addAdminCallback = () => {
    dispatch(updateIsLoadingAdminList(true));
    dispatch(addAdmin(selectedUserId, userType));
    dispatch(clearUserStore());
    setSelectedUserId("");
    setEmailInput("");
  };
  const updateUserType = (userType: string) => {
    setUserType(userType);
    dispatch(clearUserStore());
    setSelectedUserId("");
    setEmailInput("");
  };

  const selectUserCallback = (user: IUser) => {
    setSelectedUserId(user.id);
    setEmailInput(user.name);
  };

  useEffect(() => {
    setSelectedUserId("");
    setIsLoading(false);
  }, [userList]);

  useEffect(() => {
    dispatch(clearUserStore());
  }, []);
  //
  return (
    <div className="access-setting">
      <div className="em-l-container">
        <div className="em-c-table-object">
          <div className="em-c-table-object__header">
            <div className="em-c-field__body">
              <select
                className="em-c-select em-u-clickable"
                value={userType}
                onChange={(e) => updateUserType(e.target.value)}
              >
                <option value="User">User</option>
                <option value="Group">Group</option>
              </select>
            </div>
            <div className="em-c-search__body">
              <input
                type="search"
                id="search"
                className="em-c-search__input"
                placeholder="Search to find administrators"
                value={emailInput}
                onChange={(e) => emailInputCallback(e.target.value)}
              />
              {isLoading ? (
                <LoadingIcon optionalClass="access-setting__body--loading" />
              ) : null}
            </div>
            <div className="em-c-toolbar__item em-is-aligned-left">
              <div className="em-c-btn-group ">
                {selectedUserId.length > 0 ? (
                  <Button
                    name="Add Admin"
                    optionalClass="em-c-btn em-c-btn--primary"
                    callback={() => addAdminCallback()}
                  />
                ) : (
                  <button className="em-c-btn em-c-btn--disabled">
                    Add Admin
                  </button>
                )}
              </div>
            </div>
          </div>
          {emailInput.length >= 3 && selectedUserId === "" ? (
            isUserNotFound ? (
              <div
                className={`access-setting__${
                  moment(event.endTime).isAfter(moment()) && event.isExist
                    ? `user-picker no-data`
                    : `user-picker__not-exist no-data`
                }`}
              >
                {`We could not find ${userType.toLowerCase()} in the system.`}
              </div>
            ) : (
              <div
                className={`access-setting__${
                  moment(event.endTime).isAfter(moment()) && event.isExist
                    ? `user-picker`
                    : `user-picker__not-exist`
                }`}
              >
                <div className="access-setting__user-picker--list">
                  {userList.map((user: IUser) => (
                    <UserCard
                      key={user.id}
                      selectedUserId={selectedUserId}
                      user={user}
                      callback={() => selectUserCallback(user)}
                    />
                  ))}
                </div>
              </div>
            )
          ) : null}
          {isLoadingAdminList ? (
            <LoadingIndicator optionalClass="access-setting__list-loading" />
          ) : (
            <div className="access-setting__body">
              <div className="em-c-table-object__body">
                <div className="em-c-table-object__body-inner">
                  <table className="em-c-table ">
                    <thead className="em-c-table__header">
                      <tr className="em-c-table__header-row">
                        <th scope="col" className="em-c-table__header-cell">
                          Name
                        </th>
                        <th scope="col" className="em-c-table__header-cell">
                          Role
                        </th>
                        <th
                          scope="col"
                          className="em-c-table__header-cell  em-u-width-10"
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="em-c-table__body ">
                      {adminList.map((admin: IAdmin) => (
                        <tr key={admin.userId} className="em-c-table__row ">
                          <td className="em-c-table__cell em-js-cell">
                            {admin.name}
                          </td>
                          <td className="em-c-table__cell em-js-cell">
                            {admin.type.replace(/-/g, " ")}
                          </td>
                          <td className="em-c-table__cell em-js-cell icon">
                            {admin.type !== "EventX-Organizer" &&
                            admin.type !== "EventX-Administrator" ? (
                              <svg
                                className="access-setting__icon em-c-icon em-c-icon--small"
                                onClick={() => removeAdminCallback(admin)}
                              >
                                 {" "}
                                <use
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  href={`${icons}#trashcan`}
                                ></use>
                              </svg>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot className="em-c-table__footer">
                      <tr className="em-c-table__footer-row"></tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccessSetting;
