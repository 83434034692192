export interface ISetting {
    name: string,
    isActive: boolean,
    isDisable: boolean
}

interface IState {
    settingList: Array<ISetting>,
    isSendingEmail: boolean
}

const initState:IState = {
    settingList: [
        {name: 'Allow Walk In',
        isActive: false,
        isDisable: false},
        {name: 'Allow Exceed Capacity',
        isActive: false,
        isDisable: true}
    ],
    isSendingEmail: false,
}

export default (state:IState = initState, action:any) => {
    switch (action.type) {   
        case 'UPDATE_EVENT_SETTING':
            return {...state, settingList:action.payload}
        case 'INIT_EVENT_SETTING':
            return {...state, settingList:[
                {name: 'Allow Walk In',
                isActive: action.payload.allowWalkIn,
                isDisable: false},
                {name: 'Allow Exceed Capacity',
                isActive: action.payload.allowExceedCapacity,
                isDisable: !action.payload.allowWalkIn ? true : false},
            ]}
        case 'UPDATE_IS_SENDING_EMAIL':
            return {...state, isSendingEmail:action.payload} 
        default:
            return state
    }
}