import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import {
  updateEventXUser,
  updateEventXTrackerToken,
} from "./Store/Actions/user";
import Homepage from "./Pages/Homepage/Homepage";
import Questionpage from "./Pages/QuestionPage/QuestionPage";
import Landingpage from "./Pages/LandingPage/LandingPage";
import CheckInPage from "./Pages/CheckInPage/CheckInPage";
import SettingPage from "./Pages/SettingPage/SettingPage";
import WhatsNewpage from "./Pages/WhatsNewPage/WhatsNewPage";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";

import "./App.scss";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();

  const getAccessToken = async () => {
    const accessTokenRequest = {
      scopes: [`${process.env.REACT_APP_SCOPES}`],
      account: accounts[0],
    };

    if (accounts.length > 0) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          dispatch(updateEventXUser(response.idTokenClaims));
          dispatch(updateEventXTrackerToken(response.accessToken));
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenRedirect(accessTokenRequest)
              .catch((error) => console.log(error));
          }
        });
    }
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  return (
    <div className="app">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route
            path="/events/:eventId/questions/*"
            element={<Questionpage />}
          />
          <Route path="/events/:eventId/checkin/*" element={<CheckInPage />} />
          <Route path="/events/:eventId/setting/*" element={<SettingPage />} />
          <Route path="/whatsnew" element={<WhatsNewpage />} />
          <Route
            path="*"
            element={<Homepage params={window.location.search.substring(1)} />}
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
