import {useState, useEffect} from 'react'
import LoadingIcon from '../LoadingIcon/LoadingIcon'
import './Button.scss'

interface IProps {
    name: string, 
    optionalClass?: string, 
    callback: Function,
    hasLoading?: boolean,
    stopLoading?: boolean
}

const Button:React.FC<IProps> = ({name, optionalClass, callback, hasLoading, stopLoading}) => {
    const [isLoading, setIsLoading] = useState(false)

    const showLoading = () => {
        setIsLoading(true)
        callback(name)
    }

    useEffect(() => {
        if(stopLoading !== undefined){
            setIsLoading(stopLoading)
        }
    }, [stopLoading])

    return (
        <button className={`${optionalClass} em-c-btn`} onClick={() => hasLoading && (stopLoading !== undefined ? stopLoading : true) ? showLoading() : callback(name)}>
            {isLoading ? <LoadingIcon /> : name}
        </button>
    )
}

export default Button