import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./SettingSubHeader.scss";

const SettingSubHeader: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const event = useSelector((state: any) => state.app.currEvent);

  const isActiveTab = (tab: string) => {
    if (location.pathname.includes(tab)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="setting-sub-header">
      <div className="em-l-container">
        <div className="setting-sub-header__event-name">{event.name}</div>
        <div className="setting-sub-header__tabbar">
          <div
            className={`setting-sub-header__tabbar--tab ${
              isActiveTab("attendance") ? "active" : "disable"
            }`}
            onClick={() => {
              navigate(`/events/${event.eventId}/setting/attendance`);
            }}
          >
            Attendance
          </div>
          <div
            className={`setting-sub-header__tabbar--tab ${
              isActiveTab("export") ? "active" : "disable"
            }`}
            onClick={() => {
              navigate(`/events/${event.eventId}/setting/export`);
            }}
          >
            Export / Reporting
          </div>
          <div
            className={`setting-sub-header__tabbar--tab ${
              isActiveTab("access") ? "active" : "disable"
            }`}
            onClick={() => {
              navigate(`/events/${event.eventId}/setting/access`);
            }}
          >
            Admin Access
          </div>
          <div
            className={`setting-sub-header__tabbar--tab ${
              isActiveTab("options") ? "active" : "disable"
            }`}
            onClick={() => {
              navigate(`/events/${event.eventId}/setting/options`);
            }}
          >
            Settings
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingSubHeader;
