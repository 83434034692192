import {store} from '../store'
import moment from 'moment'
import EventService from '../../Services/EventService'
import SASTokenService from '../../Services/SASTokenService'
import {IEvent, ITag} from '../Reducers/event'
import {getEventsFromEventX, clearEventStore} from './event'

export const getSASToken = () => {
    const request = SASTokenService.getTutorialURL()
    return (dispatch:any) => {
        request.then((response:any) => {
            dispatch({type:'UPDATE_TUTORIAL_URL', payload:response})
        }).catch(async(error:any) => {

        })
    }
}

export const updateIsHomeBtnActive = (value:boolean) => {
    return (dispatch:any) => {
        dispatch({type:'UPDATE_IS_HOME_BUTTON_ACTIVE', payload:value})
    }
}

export const updateCurrEvent = (event:IEvent) => {
    return async(dispatch:any) => {
        let data = {eventId:event.eventId}
        if(!moment(event.endTime).add(1, 'days').isBefore(moment()) && event.isExist) {
            await EventService.duplicateEventAsync(data).then((response:any) => {
                dispatch({type:'INIT_EVENT_SETTING', payload:event})
                dispatch({type:'UPDATE_CURR_EVENT', payload:event})
                dispatch(updateIsLoadingCurrEvent(false))
            }).catch(async(error:any) => { 
                dispatch(updateErrorResponse({code:error.status, message:"Something is missing. Please try again."}))
                dispatch(updateIsLoadingCurrEvent(false))
            })
        } else {
            dispatch({type:'UPDATE_CURR_EVENT', payload:event})
            dispatch(updateIsLoadingCurrEvent(false))
        }
    }
}

export const updateCurrMonth = (month:string) => {
    return (dispatch:any) => {
        dispatch(changeFilterType('All'))
        dispatch(updateIsLoading(true))
        dispatch({type:'UPDATE_CURR_MONTH', payload:month})
    }
}

export const updateCurrYear = (year:string, month?:string, pathChangeCallback?:Function) => {
    return (dispatch:any) => {
        dispatch(clearEventStore())
        dispatch(updateErrorResponse({code:"", message:""}))    
        dispatch(getEventsFromEventX(year, month, pathChangeCallback))
        dispatch({type:'UPDATE_CURR_YEAR', payload:year})
    }
}

export const updateIsLoadingCurrEvent = (value:boolean) => {
    return (dispatch:any) => {
        dispatch({type:'UPDATE_IS_LOADING_CURR_EVENT', payload:value})
    }
}

export const updateIsLoading = (value:boolean) => {
    return (dispatch:any) => {
        dispatch({type:'UPDATE_IS_LOADING', payload:value})
    }
}

export const updateTagsStatus = (index:number) => {
    return (dispatch:any) => {
        let newTags = [...store.getState().app.tagsStatus]
        newTags[index].isActive = !newTags[index].isActive 
        dispatch({type:'UPDATE_TAGS_STATUS', payload:newTags})
        let activeTags:any = [] 
        newTags.map((tag:ITag) => tag.isActive ? activeTags.push(tag.name) : null)
        let events = store.getState().event.events[store.getState().app.currMonth]
        if(activeTags.length > 0) {
            events = filterByTags(events, activeTags)
        }
        let filterType = store.getState().app.filterType
        if(filterType != 'All') {
            events = filterByTypes(events, filterType)
        }
        dispatch({type:'UPDATE_EVENTS_OF_CURR_MONTH', payload:events})
    }
}

export const clearTagsStatus = () => {
    return (dispatch:any) => {
        let newTags = [...store.getState().app.tagsStatus]
        newTags.map((tag:ITag) => {
            tag.isActive = false
        })
        dispatch({type:'UPDATE_TAGS_STATUS', payload:newTags})
        let activeTags:any = [] 
        newTags.map((tag:ITag) => tag.isActive ? activeTags.push(tag.name) : null)
        let events = store.getState().event.events[store.getState().app.currMonth]
        if(activeTags.length > 0) {
            events = filterByTags(events, activeTags)
        }
        dispatch({type:'UPDATE_EVENTS_OF_CURR_MONTH', payload:events})
    }
}

export const filterByTags = (events:Array<IEvent>, activeTags:Array<string>) => {
    return events.filter((event:any) => {
        let isIncluded = false
        for (let index in event.tags){
            isIncluded = activeTags.includes(event.tags[index].name)
            if(isIncluded) break
        }
        return isIncluded    
    })
}

export const filterByTypes = (events:Array<IEvent>, filterType:string) => {
    if(filterType === 'Cancelled') {
        return events.filter((event:any) => {
            return !event.isExist
        })
    } else if (filterType === 'Upcoming') {
        return events.filter((event:any) => {
            return !moment(event.endTime).add(1, 'days').isBefore(moment()) && event.isExist
        })

    } else if (filterType === 'Completed') {
        return events.filter((event:any) => {
            return moment(event.endTime).add(1, 'days').isBefore(moment()) && event.isExist
        })
    }
}


export const updateFilterType = (filterType:string) => {
    return (dispatch:any) => {
        dispatch(changeFilterType(filterType))
        let newTags = [...store.getState().app.tagsStatus]
        let activeTags:any = [] 
        newTags.map((tag:ITag) => tag.isActive ? activeTags.push(tag.name) : null)
        let events = store.getState().event.events[store.getState().app.currMonth]
        if(activeTags.length > 0) {
            events = filterByTags(events, activeTags)
        }
        if(filterType != 'All') {
            events = filterByTypes(events, filterType)
        }
        dispatch({type:'UPDATE_EVENTS_OF_CURR_MONTH', payload:events})
    }
}

export const changeFilterType = (filterType:string) => {
    return (dispatch:any) => {
        dispatch({type:'UPDATE_FILTER_TYPE', payload:filterType})
    }
}

export const initTagsStatus = (events:Array<IEvent>) => {
    let uniqTags:any = [] 
    if(events){
        events.map((event:IEvent) => {
            event.tags.map((tag:any) => {
                uniqTags = uniqTags.concat(tag.name)
            })
        })
    }
    let tagsStatus:any = []
    uniqTags = uniqTags.filter((value:string, index:number, self:Array<string>) => self.indexOf(value) === index)
    uniqTags.map((tag:string) => tagsStatus.push({name: tag, isActive: false}))
    return tagsStatus
}

export const updateErrorResponse = (value:any) => {
    return (dispatch:any) => {
        dispatch({type:'UPDATE_ERROR_RESPONSE', payload:value})
    }
}

export const updateIsConfirmCheckedIn = (value:boolean) => {
    return (dispatch:any) => {
        dispatch({type:'UPDATE_IS_CONFIRM_CHECKED_IN', payload:value})
    }
}

export const updateIsLoadingCheckedIn = (value:boolean) => {
    return (dispatch:any) => {
        dispatch({type:'UPDATE_IS_LOADING_CHECKED_IN', payload:value})
    }
}

export const clearErrorResponseStore = () => {
    return (dispatch:any) => {
        dispatch({type:'CLEAR_ERROR_RESPONSE_STORE'})
    }
}

export const clearAppStore = (year?:string) => {
    return (dispatch:any) => {
        dispatch({type:'CLEAR_STORE', payload: year ? year : null})
    }
}