import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendEmailToCC } from "../../Store/Actions/setting";
import Button from "../Button/Button";
import EmailModal from "../EmailModal/EmailModal";
import PreviewData from "../PreviewData/PreviewData";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { courseValue } from "../../Utilities/CCCourseID";
import icons from "../../Assets/standard-2.0.2/images/24/em-icons.svg";
import "./ExportSetting.scss";

const ExportSetting: React.FC = () => {
    const dispatch = useDispatch();
    const sender = useSelector((state: any) => state.user.eventXUser.email);
    const error = useSelector((state: any) => state.app.errorResponse);
    const [careerConnectId, setCareerConnectId] = useState("");
    const [isShowingSection, setIsShowingSection] = useState(false);
    const [isShowingEmailModal, setIsShowingEmailModal] = useState(false);
    const [isShowingPreviewData, setIsShowingPreviewData] = useState(false);
    const [isSendingEmailToCC, setIsSendingEmailToCC] = useState(false);
    const [attendeeType, setAttendeeType] = useState("attended");
    const isLoadingAttendeeList = useSelector(
        (state: any) => state.attendee.isLoadingAttendeeList
    );
    const isLoadingCurrEvent = useSelector(
        (state: any) => state.app.isLoadingCurrEvent
    );

    const uploadToCareerConnectCallback = () => {
        if (careerConnectId !== "") {
            setIsSendingEmailToCC(true);
            dispatch(sendEmailToCC(attendeeType, careerConnectId, sender));
        }
    };

    useEffect(() => {
        if (error.message !== "") {
            setCareerConnectId("");
            setIsSendingEmailToCC(false);
        }
    }, [error]);

    return (
        <div className="export-setting">
            {isLoadingCurrEvent || isLoadingAttendeeList ? (
                <LoadingIndicator />
            ) : (
                <div className="export-setting__content em-l-container">
                    <div className="export-setting__content--section__title">
                        Data Filtering
                    </div>
                    <div className="export-setting__content--detail">
                        <div className="export-setting__content--detail_item">
                            <div className="export-setting__content--detail_item--name">
                                Attendee Status
                            </div>
                            <div className="export-setting__content--detail_item--list em-c-tooltip em-js-tooltip">
                                {attendeeType === "attended" ? (
                                    <span className="em-c-tooltip__content">
                                        <p>
                                            Including checked-in and walked-in
                                        </p>
                                    </span>
                                ) : null}
                                <select
                                    className="export-setting__content--detail_item--list-selectbox em-c-select em-c-select em-c-tooltip__trigger em-js-tooltip-trigger"
                                    value={attendeeType}
                                    onChange={(e) =>
                                        setAttendeeType(e.target.value)
                                    }
                                >
                                    <option value="attended">Attended</option>
                                    <option value="checked-in">
                                        Checked In
                                    </option>
                                    <option value="walked-in">Walked In</option>
                                    <option value="not-checked-in">
                                        Not Checked In
                                    </option>
                                    <option value="all">All</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <section
                        className={`em-c-section em-c-section--expandable ${
                            isShowingSection === true ? "" : "em-is-closed"
                        } `}
                    >
                        <header
                            className="em-c-section__header em-js-section-trigger"
                            onClick={() =>
                                setIsShowingSection(!isShowingSection)
                            }
                        >
                            <svg className="em-c-icon em-c-icon--medium em-c-section__icon">
                                <use
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    href={`${icons}#caret-down`}
                                ></use>
                            </svg>
                            <div className="export-setting__content--sectionexpand">
                                <div className="export-setting__content--section__title">
                                    Reporting
                                </div>
                                <div className="export-setting__content--section__note">
                                    (optional)
                                </div>
                            </div>
                        </header>

                        {isShowingSection ? (
                            <div className="em-c-section__body em-js-section-target">
                                <div className="export-setting__content--section">
                                    <div className="export-setting__content--section__subtitle">
                                        For Career Connect Integration
                                    </div>
                                </div>
                                <div className="export-setting__course-id--detail">
                                    <div className="export-setting__course-id">
                                        Course ID
                                    </div>
                                    <select
                                        className="export-setting__course-id--list-selectbox em-c-select em-js-tooltip-trigger"
                                        value={careerConnectId}
                                        onChange={(e) =>
                                            setCareerConnectId(e.target.value)
                                        }
                                    >
                                        <option disabled value="">
                                            Please Select Course ID
                                        </option>
                                        {courseValue.map((course: any) => (
                                            <option value={course.value}>
                                                {course.name}
                                            </option>
                                        ))}
                                    </select>
                                    <Button
                                        name="Upload into Career Connect"
                                        hasLoading
                                        stopLoading={isSendingEmailToCC}
                                        callback={() =>
                                            uploadToCareerConnectCallback()
                                        }
                                        optionalClass={
                                            careerConnectId === ""
                                                ? "em-c-btn--small em-c-btn--disabled"
                                                : " em-c-btn--small em-c-btn--primary"
                                        }
                                    />
                                </div>
                            </div>
                        ) : null}
                    </section>
                    <div className="export-setting__buttons">
                        <Button
                            name="Preview Data"
                            callback={() => setIsShowingPreviewData(true)}
                        />
                        <Button
                            name="Send Email"
                            optionalClass="em-c-btn--primary"
                            callback={() => setIsShowingEmailModal(true)}
                        />
                    </div>
                </div>
            )}
            {isShowingEmailModal ? (
                <EmailModal
                    attendeeType={attendeeType}
                    careerConnectId={careerConnectId}
                    closeCallback={() => setIsShowingEmailModal(false)}
                />
            ) : null}
            {isShowingPreviewData ? (
                <PreviewData
                    attendeeType={attendeeType}
                    careerConnectId={careerConnectId}
                    closeCallback={() => setIsShowingPreviewData(false)}
                />
            ) : null}
        </div>
    );
};

export default ExportSetting;
