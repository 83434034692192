interface IState {
    allowWalkIn: boolean,
    allowExceedCapacity: boolean,
    isUsingBadgeReader: boolean,
    isBackToBadge: boolean
}

const initState:IState = {
    allowWalkIn: false,
    allowExceedCapacity: false,
    isUsingBadgeReader: false,
    isBackToBadge: false
}

export default (state:IState = initState, action:any) => {
    switch (action.type) {   
        case 'WALK_IN_QUESTION_UPDATE':
            return {...state, allowWalkIn:action.payload}
        case 'EXCEED_CAPACITY_UPDATE':
            return {...state, allowExceedCapacity:action.payload}
        case 'BADGE_QUESTION_UPDATE':
            return {...state, isUsingBadgeReader:action.payload}
        case 'BACK_TO_BADGE_UPDATE':
            return {...state, isBackToBadge:action.payload}
        default:
            return state
    }
}