import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { backToBadgeUpdate } from "../../Store/Actions/question";
import { checkInByBadge } from "../../Store/Actions/event";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { updateIsConfirmCheckedIn } from "../../Store/Actions/app";
import picture from "../../Assets/images/exxon-badge-icon.svg";
import avatar from "../../Assets/images/default-avatar-icon-48px.svg";
import "./BadgeReader.scss";

const BadgeReader: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [badgeNumber, setBadgeNumber] = useState("");
  const inputRef = useRef(document.createElement("input"));
  const event = useSelector((state: any) => state.app.currEvent);
  const errorResponse = useSelector((state: any) => state.app.errorResponse);
  const isConfirmCheckedIn = useSelector(
    (state: any) => state.app.isConfirmCheckedIn
  );
  const isLoadingCheckedIn = useSelector(
    (state: any) => state.app.isLoadingCheckedIn
  );
  const user = useSelector((state: any) => state.user.user);
  const [photoError, setPhotoError] = useState(false);
  let timer: any;

  useEffect(() => {
    if (isConfirmCheckedIn) {
      setBadgeNumber("");
      timer = setTimeout(() => {
        dispatch(updateIsConfirmCheckedIn(false));
      }, 3500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isConfirmCheckedIn]);

  const refocus = () => {
    inputRef.current.focus();
  };

  const checkInByBadgeCallback = (e: any) => {
    clearTimeout(timer);
    dispatch(updateIsConfirmCheckedIn(false));
    e.preventDefault();
    dispatch(checkInByBadge(badgeNumber));
    setBadgeNumber("");
  };

  const checkInByEmail = () => {
    dispatch(backToBadgeUpdate(true));
    navigate(`/events/${event.eventId}/checkin/email`);
  };

  useEffect(() => {
    if (errorResponse.message !== "") {
      setBadgeNumber("");
    } else {
      refocus();
    }
  }, [errorResponse]);

  useEffect(() => {
    if (!isLoadingCheckedIn) refocus();
  }, [isLoadingCheckedIn]);

  return (
    <div className="badge-reader">
      <div className="badge-reader__body">
        <div
          className={`badge-reader__body--title ${
            isConfirmCheckedIn ? "tap" : "non-tap"
          }`}
        >
          {isConfirmCheckedIn
            ? "Tap your smartcard to check-in"
            : "Welcome! Please check-in"}
        </div>

        {!isConfirmCheckedIn ? (
          <div className="badge-reader__body--sub-title">
            Scan your badge on the reader to get started
          </div>
        ) : null}

        {isLoadingCheckedIn ? (
          <LoadingIndicator optionalClass="badge-reader__body--loading-indicator" />
        ) : (
          <div className="badge-reader__context">
            {isConfirmCheckedIn ? (
              <div className="checkin-confirmation">
                <img
                  className="checkin-confirmation__picture"
                  alt="user_picture"
                  src={
                    user.lanId !== undefined && !photoError
                      ? `https://mysite.na.xom.com/User%20Photos/Profile%20Pictures/${user.lanId.replace(
                          /\\/g,
                          "_"
                        )}_LThumb.jpg`
                      : avatar
                  }
                  onError={() => setPhotoError(true)}
                />
                <div className="checkin-confirmation__subtitle">
                  You’re all checked in.
                </div>
                <div className="checkin-confirmation__title">{`Thanks ${user.name}!`}</div>
              </div>
            ) : (
              <img className="badge-reader__body--picture" src={picture} />
            )}
            <form onSubmit={(e) => checkInByBadgeCallback(e)}>
              <input
                className="badge-reader__body--hidden-input"
                ref={inputRef}
                type="password"
                onBlur={() => refocus()}
                value={badgeNumber}
                onChange={(e) => setBadgeNumber(e.target.value)}
              />
            </form>
            <div className="badge-reader__body--sub-title">
              Or click{" "}
              <span
                className="badge-reader__body--link"
                onClick={() => checkInByEmail()}
              >
                here
              </span>{" "}
              to check-in using your name or e-mail address
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BadgeReader;
