import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { updateCurrYear, updateCurrMonth } from "../../Store/Actions/app";
import "./MonthPicker.scss";

const MonthPicker: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const events = useSelector((state: any) => state.event.events);
  const currMonth = useSelector((state: any) => state.app.currMonth);
  const currYear = useSelector((state: any) => state.app.currYear);

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };
  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const setMonth = (month: string) => {
    navigate(`/events?year=${currYear}&month=${month}`);
    dispatch(updateCurrMonth(month));
    dispatch(updateCurrYear(currYear, month));
  };

  return (
    <div className="month-picker">
      {windowDimensions.width <= 880 ? (
        <select
          className="month-picker em-c-select em-u-clickable"
          value={currMonth}
          onChange={(e) =>
            events[e.target.value].length > 0
              ? setMonth(e.target.value)
              : undefined
          }
        >
          {Object.keys(events).map((month: string) => (
            <option
              key={month}
              value={month}
              className={`month-picker__month ${
                events[month].length > 0
                  ? currMonth === month
                    ? "active"
                    : "heldEvent"
                  : "disable"
              }`}
            >
              {moment(month, "MMM").format("MMMM")}
            </option>
          ))}
        </select>
      ) : (
        Object.keys(events).map((month: string) => (
          <div
            key={month}
            className={`month-picker__month ${
              events[month].length > 0
                ? currMonth === month
                  ? "active"
                  : "heldEvent"
                : "disable"
            }`}
            onClick={
              events[month].length > 0 ? () => setMonth(month) : undefined
            }
          >
            {month}
          </div>
        ))
      )}
    </div>
  );
};

export default MonthPicker;
