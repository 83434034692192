import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateTagsStatus } from "../../Store/Actions/app";
import { ITag } from "../../Store/Reducers/event";
import EventFilterModal from "../EventFilterModal/EventFilterModal";
import icons from "../../Assets/standard-2.0.2/images/24/em-icons.svg";
import "./EventFilter.scss";

const EventFilter: React.FC = () => {
    const dispatch = useDispatch();
    const [isShowingModal, setIsShowingModal] = useState(false);
    const tagsStatus = useSelector((state: any) => state.app.tagsStatus);

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    };
    const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
    };

    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const filterCallback = (index: number) => {
        dispatch(updateTagsStatus(index));
    };

    return (
        <div className="event-filter">
            <div
                className="event-filter__container"
                onClick={() => setIsShowingModal(true)}
            >
                {windowDimensions.width <= 880 ? null : (
                    <div
                        className="event-filter__text"
                        title={
                            tagsStatus.find((x: ITag) => x.isActive) !==
                            undefined
                                ? "On"
                                : "Off"
                        }
                    >
                        Tag Filter
                    </div>
                )}
                <svg
                    className={`event-filter__icon em-c-icon em-c-icon--small ${
                        tagsStatus.find((x: ITag) => x.isActive) === undefined
                            ? "disable"
                            : ""
                    }`}
                >
                                    
                    <use
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        href={`${icons}#filter`}
                    ></use>
                                
                </svg>
            </div>
            {isShowingModal ? (
                <EventFilterModal
                    tags={tagsStatus}
                    filterCallback={(idx: number) => filterCallback(idx)}
                    closeCallback={() => setIsShowingModal(false)}
                />
            ) : null}
        </div>
    );
};

export default EventFilter;
