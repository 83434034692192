import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    updateUserList,
    updateUser,
    clearUserStore,
} from "../../Store/Actions/user";
import { IUser } from "../../Store/Reducers/user";
import { checkInByEmail } from "../../Store/Actions/event";
import UserCard from "../UserCard/UserCard";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import Button from "../Button/Button";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { updateIsConfirmCheckedIn } from "../../Store/Actions/app";
import icons from "../../Assets/standard-2.0.2/images/24/em-icons.svg";
import avatar from "../../Assets/images/default-avatar-icon-48px.svg";
import "./UserPicker.scss";
import "../Confirmation/CheckInConfirmation.scss";

const UserPicker: React.FC = () => {
    const dispatch = useDispatch();
    const inputRef = useRef(document.createElement("input"));
    const [isActive, setActive] = useState(false);
    const [emailInput, setEmailInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const selectedUserId = useSelector((state: any) => state.user.user.id);
    const selectedUserEmail = useSelector(
        (state: any) => state.user.user.email
    );
    const userList = useSelector((state: any) => state.user.userList);
    const isUserNotFound = useSelector(
        (state: any) => state.user.isUserNotFound
    );
    const isLoadingCheckedIn = useSelector(
        (state: any) => state.app.isLoadingCheckedIn
    );
    const isConfirmCheckedIn = useSelector(
        (state: any) => state.app.isConfirmCheckedIn
    );
    const [photoError, setPhotoError] = useState(false);
    const user = useSelector((state: any) => state.user.user);
    let timer: any;

    useEffect(() => {
        if (isConfirmCheckedIn) {
            timer = setTimeout(() => {
                dispatch(updateIsConfirmCheckedIn(false));
            }, 2800);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [isConfirmCheckedIn]);

    const selectUserCallback = (user: IUser) => {
        dispatch(updateUser(user));
    };

    const emailInputCallback = (value: string) => {
        setEmailInput(value);
        dispatch(clearUserStore());
        if (value.length >= 3) {
            setIsLoading(true);
            dispatch(updateUserList(value, "User"));
        }
    };

    const confirmCheckedIn = () => {
        setEmailInput("");
        dispatch(checkInByEmail());
        clearTimeout(timer);
        dispatch(updateIsConfirmCheckedIn(false));
    };

    useEffect(() => {
        setIsLoading(false);
    }, [userList]);

    useEffect(() => {
        setEmailInput(selectedUserEmail);
    }, [selectedUserEmail]);

    useEffect(() => {
        if (!isConfirmCheckedIn) {
            inputRef.current.focus();
            setEmailInput("");
            dispatch(clearUserStore());
        }
    }, [isConfirmCheckedIn]);

    return (
        <div className="user-picker">
            <div className="user-picker__body">
                {isLoadingCheckedIn ? (
                    <LoadingIndicator optionalClass="badge-reader__body--loading-indicator" />
                ) : !isConfirmCheckedIn ? (
                    <div className="user-picker__body-container">
                        <div className="user-picker__body--title">
                            Let's get you checked in
                        </div>
                        <div
                            className={`em-c-field ${
                                isActive ? "em-is-active" : ""
                            }`}
                        >
                            <div className="user-picker__body--input-field em-c-field__body">
                                <label className="user-picker__body--input-field--title">
                                    Email
                                </label>
                                <input
                                    ref={inputRef}
                                    className="em-c-input"
                                    onBlur={() => setActive(false)}
                                    onFocus={() => setActive(true)}
                                    value={emailInput}
                                    onChange={(e) =>
                                        emailInputCallback(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        {isLoading ? (
                            <LoadingIcon optionalClass="user-picker__body--loading" />
                        ) : isUserNotFound ? (
                            <div
                                className="em-c-alert em-c-alert--warning"
                                role="alert"
                            >
                                <svg className="em-c-icon em-c-icon--small em-c-alert__icon">
                                    <use
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        href={`${icons}#warning`}
                                    ></use>
                                </svg>
                                <div className="em-c-alert__body">
                                    We could not find you in the system.
                                </div>
                            </div>
                        ) : selectedUserId ? (
                            <Button
                                name="Please Check-in"
                                optionalClass="user-picker__body--button em-c-btn--primary"
                                callback={() => confirmCheckedIn()}
                            />
                        ) : emailInput.length >= 3 ? (
                            <div className="user-picker__body--list">
                                {userList.map((user: IUser) => (
                                    <UserCard
                                        key={user.id}
                                        selectedUserId={selectedUserId}
                                        user={user}
                                        callback={selectUserCallback}
                                        isCheckIn={true}
                                    />
                                ))}
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <div className="checkin-confirmation">
                        <div className="checkin-confirmation__subtitle">
                            You’re all checked in.
                        </div>
                        <img
                            className="checkin-confirmation__picture"
                            alt="avatar"
                            src={
                                user.lanId !== undefined && !photoError
                                    ? `https://mysite.na.xom.com/User%20Photos/Profile%20Pictures/${user.lanId.replace(
                                          /\\/g,
                                          "_"
                                      )}_LThumb.jpg`
                                    : avatar
                            }
                            onError={() => setPhotoError(true)}
                        />
                        <div className="checkin-confirmation__title">{`Thanks ${user.name}!`}</div>
                    </div>
                )}
                {/* {isConfirmCheckedIn ? <CheckInConfirmation /> : null} */}
            </div>
        </div>
    );
};

export default UserPicker;
