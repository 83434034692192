import {store} from '../Store/store'

class AttendeeService {
    static async getAttendees(eventId:string){
        let response = await fetch(`${process.env.REACT_APP_EVENTX_TRACKER_API_URL}/AttendeeGet?code=${process.env.REACT_APP_API_CODE}&eventId=${eventId}`,
            {
                method:'GET',
                mode:'cors',
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer ' + store.getState().user.eventXTrackerToken,
                }
            }
        )
        if(await response.status !== 200){ throw response }
        return await response.json()
    }
}

export default AttendeeService