import {store} from '../Store/store'

class UserService {
    static async getUsersAsync(search:string, userType:string, signal:AbortSignal){
        let response = await fetch(`${process.env.REACT_APP_EVENTX_TRACKER_API_URL}/UserGet?code=${process.env.REACT_APP_API_CODE}&search=${search}&userType=${userType}`,
            {
                method:'GET',
                mode:'cors',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer ' + store.getState().user.eventXTrackerToken,
                }
            }
        )
        if(response.status !== 200){ throw response }
        return await response.json()
    }
}

export default UserService