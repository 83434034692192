import React from 'react'
import ButtonWrapper from '../ButtonWrapper/ButtonWrapper'
import './Question.scss'

interface IProps {
    optionalClass?: string,
    question: string, 
    firstDetail: string,
    secondDetail?: string, 
    yesCallback: Function, 
    noCallback: Function,
    optionalCheckbox?: string,
    checkboxCallback?: Function 
}

const Question:React.FC<IProps> = ({optionalClass, question, firstDetail, secondDetail, yesCallback, noCallback, optionalCheckbox, checkboxCallback}) => {
    return (
        <div className="question" >
            <div className="question__body">
                <div className={optionalClass? `question__content--${optionalClass}`: `question__content`}>
                    <div className="question__text">
                        {question}
                    </div>             
                    <div className="question__subtext">
                        {firstDetail}
                    </div>
                    {secondDetail? 
                    <div className="question__subtext">
                        {secondDetail}
                    </div>
                    : null}    
                    {optionalCheckbox ?
                    <div className="question__checkbox">
                        <label className="em-c-input-group">
                            <input id="inline-check-1" type="checkbox" name="checkbox-1" className="em-c-input-group__control" onChange={(e) => checkboxCallback ? checkboxCallback(e.target.checked) : null}/>
                            <span className="em-c-input-group__text">{optionalCheckbox}</span>
                        </label>
                    </div> 
                    : null}
                </div>
                <div className="question__buttons">
                    <ButtonWrapper 
                        firstBtnName="Yes"
                        secondBtnName="No"
                        firstBtnCallback={yesCallback}
                        secondBtnCallback={noCallback}
                        firstOptionalClass="btn-large em-c-btn--primary"
                        secondOptionalClass="btn-large em-c-btn--primary"
                    />
                </div>
            </div>
        </div>
    )
}

export default Question