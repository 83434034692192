import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  walkInQuestionUpdate,
  exceedCapacityUpdate,
} from "../../Store/Actions/question";
import { updateIsHomeBtnActive } from "../../Store/Actions/app";
import Question from "../../Components/Questions/Question";

const WalkInQuestion: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const event = useSelector((state: any) => state.app.currEvent);

  const questionCallback = (value: string) => {
    let allowWalkIn = false;
    if (value.toLowerCase() === "yes") allowWalkIn = true;
    dispatch(walkInQuestionUpdate(allowWalkIn));
    dispatch(updateIsHomeBtnActive(true));
    navigate(`/events/${event.eventId}/questions/badge`);
  };

  const setAllowExceedCapacity = (value: boolean) => {
    dispatch(exceedCapacityUpdate(value));
  };

  useEffect(() => {
    dispatch(updateIsHomeBtnActive(false));
  }, []);

  return (
    <Question
      question="Do you want to allow walk-ins?"
      firstDetail="Attendees will be a able to check-in for this event as long as there are enough seats 
            available in your EventX event."
      optionalClass="walkin"
      optionalCheckbox="Allow walk-in attendees even though it exceeds maximum capacity."
      checkboxCallback={setAllowExceedCapacity}
      yesCallback={questionCallback}
      noCallback={questionCallback}
    />
  );
};

export default WalkInQuestion;
