import React from 'react'
import './ErrorCard.scss'

const ErrorCard:React.FC = () => {
    return (
        <div className="error-card">
            <div className="error-card__container">
                <div className="error-card__text">
                    No events were found with the current filters.
                </div>
                <div className="error-card__subtext">   
                    Why don't you create one at
                    <span>
                        <a href='https://eventx.exxonmobil.com/' className="error-card__subtext--link"> here</a>
                    </span>
                </div>    
            </div>      
        </div>
    )
}

export default ErrorCard