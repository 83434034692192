import {combineReducers} from 'redux'
import app from './app'
import question from './question'
import event from './event'
import user from './user'
import attendee from './attendee'
import setting from './setting'
import admin from './admin'

export default combineReducers({
	app,
	question,
	event,
	user,
	attendee,
	setting,
	admin
})