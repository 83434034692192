import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import "./LandingPage.scss";

const QuestionPage: React.FC = () => {
  const navigate = useNavigate();

  const startCallback = () => {
    navigate(
      `events?year=${moment().format("YYYY")}&month=${moment().format("MMM")}`
    );
  };

  return (
    <div className="landing-page">
      <div className="landing-page__text">
        Tracking attendance has never been easier.
      </div>
      <Button name="Get Started" callback={() => startCallback()} />
    </div>
  );
};

export default QuestionPage;
