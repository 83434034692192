import AttendeeService from '../../Services/AttendeeService'
import {updateErrorResponse} from './app'
import {IAttendee} from '../Reducers/attendee'

export const getAttendeeList = (eventId:string) => {
    return (dispatch:any) => {
        const request = AttendeeService.getAttendees(eventId)
        request.then((attendees:any) => {
            dispatch(updateAttendee(attendees))
            dispatch(updateIsLoadingAttendeeList(false))
        }).catch(async(error:any) => {
            if(error.status === 401){
                let resMessage = await error.text()
                if(resMessage === "You are not authorized to access this function."){
                    dispatch(updateErrorResponse({code:"401", message:resMessage}))   
                } else {
                    dispatch(updateErrorResponse({code:"401", message:"Session Expired"}))   
                } 
            } else {
                let resMessage = await error.text()
                dispatch(updateErrorResponse({code:error.status, message:resMessage || error.statusText}))
            }
        })
    }
}

export const updateAttendee = (attendees:any) => {
    return (dispatch:any) => {
        attendees.sort((attendeeA:IAttendee, attendeeB:IAttendee) => attendeeA.name.localeCompare(attendeeB.name, 'en', {sensitivity: 'base'}))
        dispatch({type:'UPDATE_ATTENDEE_LIST', payload:attendees})
    }
} 

export const updateIsLoadingAttendeeList = (value:boolean) => {
    return (dispatch:any) => {
        dispatch({type:'UPDATE_IS_LOADING_ATTENDEE_LIST', payload:value})
    }
}