import moment from 'moment'
import {store} from '../store'
import {initTagsStatus, updateErrorResponse, updateIsConfirmCheckedIn, updateIsLoadingCheckedIn, updateIsLoading, updateCurrEvent, updateIsLoadingCurrEvent} from './app'
import {getAttendeeList} from './attendee'
import {getAdminList} from './admin'
import {updateUser} from './user'
import BadgeService from '../../Services/BadgeService'
import EventService from '../../Services/EventService'
import CheckInService from '../../Services/CheckInService'

let controller:AbortController
let signal:AbortSignal

export const getEventsFromEventX = (year:string, month?:string, pathChangeCallback?: Function) => {
    if (controller !== undefined) {
        controller.abort()
    }
    controller = new AbortController()
    signal = controller.signal
    let monthNum = month !== undefined ? `${moment(month, 'MMM').month()+1}`: moment().format('YYYY') === year ? `${moment().month()+1}` : month
    const request = EventService.getEventsAsync(year, signal, monthNum)
    return (dispatch:any) => {
        request.then((response:any) => {
            if(Object.keys(response).length !== 0){
                dispatch({type:'UPDATE_EVENTS', payload:response})
                let currMonth = month
                if(`${year}` !== moment().format('YYYY') && month === undefined){
                    const events = store.getState().event.events
                    for (let m in events){
                        if(events[m].length > 0){
                            currMonth = m
                            break
                        } 
                    }
                } else if(`${year}` === moment().format('YYYY') && month === undefined){
                    currMonth = moment().format('MMM')
                }
                dispatch({type:'UPDATE_CURR_MONTH', payload:currMonth})
                if(month === undefined && currMonth !== undefined && pathChangeCallback !== undefined){
                    pathChangeCallback(year, currMonth)
                }
                const eventsOfCurrMonth = currMonth !== undefined ? store.getState().event.events[currMonth] : store.getState().event.events[moment(currMonth, 'MMM').month()]
                dispatch({type:'UPDATE_EVENTS_OF_CURR_MONTH', payload:eventsOfCurrMonth})
                const tagsStatus = initTagsStatus(eventsOfCurrMonth)
                dispatch({type:'UPDATE_TAGS_STATUS', payload:tagsStatus}) 
                dispatch(updateErrorResponse({code:"", message:""}))    
                dispatch(updateIsLoading(false))
            }
        }).catch(async(error:any) => {
            if(error.status === 401){
                dispatch(updateErrorResponse({code:"401", message:"Session Expired"}))           
            } else if (error.name === 'AbortError'){
                console.error("The user aborted a request.")                  
            } else {
                let resMessage = await error.text()
                dispatch(updateErrorResponse({code:error.status, message:resMessage || error.statusText }))
            }   
        })
    }
}


export const getEventInfo = (eventId:string, source:string) => {
    if (controller !== undefined) {
        controller.abort()
    }
    controller = new AbortController()
    signal = controller.signal
    const request = EventService.getEventInfoAsync(eventId, signal)
    return (dispatch:any) => {
        request.then((event:any) => {
            if((source === 'question' || source === 'checkin') && moment(event.endTime).add(1, 'days').isBefore(moment())){
                dispatch(updateErrorResponse({code:"401", message:"Event is already finished"}))
                dispatch(updateIsLoadingCurrEvent(false))
            } else {
                if(source === 'setting'){
                    dispatch(getAttendeeList(`${eventId}`))    
                    dispatch(getAdminList(`${eventId}`))
                }
                dispatch({type:'WALK_IN_QUESTION_UPDATE', payload:event.allowWalkIn})
                dispatch({type:'EXCEED_CAPACITY_UPDATE', payload:event.allowExceedCapacity})
                dispatch(updateCurrEvent(event))
            }
        }).catch(async(error:any) => {
            dispatch(updateIsLoadingCurrEvent(false))
            if(error.status === 401){
                let resMessage = await error.text() 
                if(resMessage === "You are not authorized to access this function."){
                    dispatch(updateErrorResponse({code:"401", message:resMessage}))   
                } else {
                    dispatch(updateErrorResponse({code:"401", message:"Session Expired"}))   
                }
            } else if (error.name === 'AbortError'){
                console.error("The user aborted a request.")                  
            } else {
                let resMessage = await error.text()
                dispatch(updateErrorResponse({code:error.status, message:resMessage || error.statusText }))
            }
        })
    }
}

export const checkInByEmail = () => {
    if (controller !== undefined) {
        controller.abort();
    }
    controller = new AbortController()
    signal = controller.signal
    const checkInService = new CheckInService(store.getState().user.user.id, store.getState().app.currEvent.eventId, "")
    const request = checkInService.checkInByEmail(signal)
    return (dispatch:any) => {
        dispatch(updateIsLoadingCheckedIn(true))
        request.then((response:any) => {
            let data = {
                id: response.id,
                name: response.displayName,
                email: response.mail,
                lanId: response.extension_d6448b80620845bf89c63bb6933c28da_XOM_FullLANID
            }
            dispatch(updateUser(data))
            dispatch(updateIsConfirmCheckedIn(true))
            dispatch(updateIsLoadingCheckedIn(false))
        })
        .catch(async(error:any) => {
            if(error.status === 401){
                dispatch(updateErrorResponse({code:"401", message:"Session Expired"}))   
            } else if (error.name === 'AbortError'){
                console.error("The user aborted a request.")       
            } else {
                let resMessage = await error.text()
                dispatch(updateErrorResponse({code:error.status, message:resMessage || error.statusText}))
            }
            dispatch(updateIsLoadingCheckedIn(false))
        })
    }
}

export const checkInByBadge = (badgeNumber:string) => {
    if(badgeNumber === ''){
        return (dispatch:any) => {
            dispatch(updateErrorResponse({code:'400', message:'Invalid badge number. Please try again.'}))
        }
    }
    badgeNumber = badgeNumber.replace(':', '_');

    if (controller !== undefined) {
        controller.abort();
    }
    controller = new AbortController()
    signal = controller.signal
    const badgeService = new BadgeService(badgeNumber)
    const request = badgeService.getUserByBadge(badgeNumber)
    return (dispatch:any) => {
        dispatch(updateIsLoadingCheckedIn(true))
        request.then((response:any) => {
            let data = {
                id: response.id,
                name: response.displayName,
                email: response.mail,
                lanId: response.extension_d6448b80620845bf89c63bb6933c28da_XOM_FullLANID
            }
            dispatch(updateUser(data))
            dispatch(updateIsConfirmCheckedIn(true))
            dispatch(updateIsLoadingCheckedIn(false))
        })
        .catch(async(error:any) => {
            if(error.status === 401){
                dispatch(updateErrorResponse({code:'401', message:'Session Expired'}))   
            } else if (error.name === 'AbortError'){
                console.error('The user aborted a request.')     
            } else if (error == 'TypeError: Failed to fetch'){
                dispatch(updateErrorResponse({code:'400', message:'Wrong format. Please set your keyboard language to be English(US).'}))
            } else {
                let resMessage = await error.text()
                if(resMessage === '{"Message":"Facility code missing!"}' || resMessage === '{"Message":"An error has occurred."}' || error.statusText === 'Not Found'){
                    resMessage = 'User not found in badge database. Please check-in to the event by using an e-mail.'
                }
                dispatch(updateErrorResponse({code:error.status, message:resMessage || error.statusText}))
            }   
            dispatch(updateIsLoadingCheckedIn(false))
        })
    }     
}

export const duplicateEvent = (eventId:string, source:string) => {
    return async(dispatch:any) => {
        let data = {eventId:eventId}
        if(eventId !== "") {
            await EventService.duplicateEventAsync(data).then((response:any) => {
                dispatch(getEventInfo(`${eventId}`, `${source}`))
            }).catch(async(error:any) => { 
                dispatch(updateErrorResponse({code:error.status, message:"Something is missing. Please try again."}))
            })
        }
    }
}

export const clearEventStore = () => {
    return (dispatch:any) => {
        dispatch({type:'CLEAR_EVENTS_STORE'})
    }
}