export interface IUser {
    id: string,
    name: string,
    email: string,
    lanId: string,
    status?: string
}

export interface IEventXUser {
    id: string,
    name: string,
    email: string,
}

export interface IEventXUser {
    id: string,
    name: string,
    email: string,
}

interface IState {
    eventXTrackerToken: string,
    eventXUser: IEventXUser,
    userList: Array<IUser>,
    user: IUser,
    isUserNotFound: boolean
}

const initState:IState = {
    eventXTrackerToken: "",
    eventXUser: {
        id: "",
        name: "",
        email: ""
    },
    userList: [],
    user: {
       id: "",
       name: "",
       email: "",
       lanId: "",
       status: ""
    },
    isUserNotFound: false
}

export default (state:IState = initState, action:any) => {
    switch (action.type) {   
        case 'UPDATE_USER_LIST':
            return {...state, userList:action.payload}
        case 'UPDATE_USER':
            return {...state, user:action.payload}
        case 'UPDATE_IS_USER_NOT_FOUND':
            return {...state, isUserNotFound:action.payload}
        case 'UPDATE_EVENTX_TRACKER_TOKEN':
            return {...state, eventXTrackerToken:action.payload}
        case 'CLEAR_USER_STORE':
            return {...state, user:initState.user, userList:initState.userList, isUserNotFound:initState.isUserNotFound}
        case 'UPDATE_EVENTX_USER':
            return {...state, eventXUser:{id:action.payload.oid, name:action.payload.name, email:action.payload.preferred_username}}    
        case 'AAD_LOGIN_SUCCESS':
            return {...state, eventXUser:{id:action.payload.account.idToken.oid, name:action.payload.account.name, email:action.payload.account.userName}}
        case 'AAD_LOGOUT_SUCCESS':
            return {...state, eventXUser:initState.eventXUser}
        default:
            return state
    }
}