import React from "react";
import icons from "../../Assets/standard-2.0.2/images/24/em-icons.svg";
import "./IconButton.scss";

interface IProps {
    icon: string;
    callback: Function;
    optionalText?: string;
    optionalClass?: string;
    secondIcon?: string;
}

const IconButton: React.FC<IProps> = ({
    optionalClass,
    icon,
    secondIcon,
    callback,
    optionalText,
}) => {
    return (
        <button
            className={`em-c-btn ${optionalClass}`}
            onClick={() => callback()}
        >
            <div className="em-c-btn__inner">
                {optionalText ? (
                    <span className="em-c-btn__text">{optionalText}</span>
                ) : null}
                <svg className="em-c-icon em-c-icon--medium em-c-btn__icon">
                    <use
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        href={`${icons}#${icon}`}
                    ></use>
                </svg>
                {secondIcon ? (
                    <svg className="em-c-icon em-c-icon--medium em-c-btn__icon">
                        <use
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            href={`${icons}#${secondIcon}`}
                        ></use>
                    </svg>
                ) : null}
            </div>
        </button>
    );
};

export default IconButton;
