import { useEffect } from "react";
import { useSelector } from "react-redux";
import SubHeader from "../../Components/SubHeader/SubHeader";
import VersionCard from "../../Components/VersionCard/VersionCard";

import "./WhatsNewPage.scss";

const WhatsNewPage: React.FC = () => {
    const versions = useSelector((state: any) => state.app.versions);

    useEffect(() => {
        console.log("versions", versions);
    }, [versions]);

    return (
        <div className="whatsnewpage">
            <SubHeader />
            <div className="whatsnewpage em-l-container">
                {versions.map((version: any) => (
                    <VersionCard
                        key={version.version}
                        version={version.version}
                        date={version.releaseDate}
                        details={version.details}
                    />
                ))}
            </div>
        </div>
    );
};

export default WhatsNewPage;
