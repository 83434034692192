import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import { clearErrorResponseStore } from "../../Store/Actions/app";
import { getEventInfo } from "../../Store/Actions/event";
import { updateIsLoadingCurrEvent } from "../../Store/Actions/app";
import { initState } from "../../Store/Reducers/app";
import LoadingIndicator from "../../Components/LoadingIndicator/LoadingIndicator";
import EventHeader from "../../Components/EventHeader/EventHeader";
import ErrorModal from "../../Components/ErrorModal/ErrorModal";
import WalkInQuestion from "./WalkInQuestion";
import BadgeQuestion from "./BadgeQuestion";
import "./QuestionPage.scss";
import DisclaimerPopup from "../../Components/DisclaimerPopup/DisclaimerPopup";

const QuestionPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currEvent = useSelector((state: any) => state.app.currEvent);
  const isLoadingCurrEvent = useSelector(
    (state: any) => state.app.isLoadingCurrEvent
  );
  const [isShowingError, setIsShowingError] = useState(false);
  const [isShowingDisclaimer, setIShowingDisclaimer] = useState(false);
  const errorResponse = useSelector((state: any) => state.app.errorResponse);
  const event = useSelector((state: any) => state.app.currEvent);
  const eventXTrackerToken = useSelector(
    (state: any) => state.user.eventXTrackerToken
  );
  const { eventId } = useParams<any>();

  const processCallback = () => {
    if (
      errorResponse.message === "Something is missing. Please try again." ||
      errorResponse.message === "Session Expired"
    ) {
    } else if (
      errorResponse.message ===
        "You are not authorized to access this function." ||
      errorResponse.message === "Event is already finished"
    ) {
      navigate("/events");
    }
    setIsShowingError(false);
    dispatch(clearErrorResponseStore());
  };

  const understandCallback = () => {
    // dispatch for update understandDisclaimer
    setIShowingDisclaimer(false);
  };

  const backCallback = () => {
    navigate("/events");
    setIShowingDisclaimer(false);
  };
  useEffect(() => {
    if (errorResponse.message !== "") {
      setIsShowingError(true);
    }
  }, [errorResponse]);

  useEffect(() => {
    if (event === initState.currEvent && eventXTrackerToken !== "") {
      dispatch(updateIsLoadingCurrEvent(true));
      dispatch(getEventInfo(`${eventId}`, "question"));
    }
  }, [eventXTrackerToken]);

  useEffect(() => {
    if (currEvent.understandDisclaimer !== true) {
      setIShowingDisclaimer(true);
    }
  }, []);

  return (
    <div className="question-page">
      {isLoadingCurrEvent ? (
        <LoadingIndicator />
      ) : (
        <div className="question-page__container">
          <EventHeader />
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  to={`/events/${currEvent.eventId}/questions/${
                    currEvent.allowWalkIn === null ? `walk-in` : `badge`
                  }`}
                />
              }
            />
            <Route path="/walk-in" element={<WalkInQuestion />} />
            <Route path="/badge" element={<BadgeQuestion />} />
          </Routes>
          {isShowingError ? (
            <ErrorModal
              question={errorResponse.message}
              firstBtnName={
                errorResponse.message ===
                "Something is missing. Please try again."
                  ? "Try again"
                  : errorResponse.message === "Session Expired"
                  ? "Refresh"
                  : errorResponse.message ===
                      "You are not authorized to access this function." ||
                    errorResponse.message === "Event is already finished"
                  ? "Home"
                  : "Back to Process"
              }
              firstBtnCallback={processCallback}
            />
          ) : isShowingDisclaimer ? (
            <DisclaimerPopup
              header="Disclaimer"
              question="If attendance information should be retained, it must be transferred to the appropriate learning system of record (e.g. Career Connect or WebCAT)."
              firstBtnCallback={understandCallback}
              secondBtnCallback={backCallback}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default QuestionPage;
