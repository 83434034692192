import React from 'react'
import Button from '../Button/Button'
import './DisclaimerPopup.scss'

interface IProps {
    header:string,
    question:string, 
    firstBtnCallback:Function, 
    secondBtnCallback:Function,
    firstBtnName?:string,
    secondBtnName?:string
}

const DisclaimerPopup: React.FC<IProps> = ({header, question, firstBtnCallback, secondBtnCallback, firstBtnName, secondBtnName}) => {
    return (
        <div className="overlay">
            <div className="disclaimer-popup">
                <div className="disclaimer-popup__header">
                    {header}
                </div>
                <div className="disclaimer-popup__question">
                    {question}
                </div>
                <div className="disclaimer-popup__buttons">
                    <Button name={firstBtnName ? firstBtnName : "I understand"} callback={firstBtnCallback} optionalClass={"em-c-btn--primary em-u-margin-right"} />
                    <Button name={secondBtnName ? secondBtnName : "Back"} callback={secondBtnCallback} />
                </div>
            </div>
        </div>
    )
}

export default DisclaimerPopup