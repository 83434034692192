import React from 'react'
import Button from '../Button/Button'
import {Link} from 'react-router-dom'

interface IProps {
    firstBtnName: string,
    secondBtnName: string, 
    firstBtnPath?: string,
    secondBtnPath?: string,
    firstBtnCallback?: Function, 
    secondBtnCallback?: Function,
    firstOptionalClass?: string,
    secondOptionalClass?: string
}

const ButtonWrapper:React.FC<IProps> = ({firstBtnName, secondBtnName, firstBtnPath, secondBtnPath, firstBtnCallback, secondBtnCallback, firstOptionalClass, secondOptionalClass}) => { 
    return (
        <div className="em-l-grid em-l-grid--halves">
            <div className="em-l-grid__item em-u-margin-right">
                {firstBtnPath && !firstBtnCallback ?
                <Link to={firstBtnPath ? firstBtnPath : ""} className={`em-c-btn ${firstOptionalClass}`}>{firstBtnName}</Link>
                :
                <Button name={firstBtnName} optionalClass={firstOptionalClass} callback={firstBtnCallback ? firstBtnCallback : () => {}}/>
                }
            </div>
            <div className="em-l-grid__item em-u-margin-left">
                {secondBtnPath && !secondBtnCallback ?
                <Link to={secondBtnPath ? secondBtnPath : ""} className={`em-c-btn ${secondOptionalClass}`}>{secondBtnName}</Link>
                :
                <Button name={secondBtnName} optionalClass={secondOptionalClass} callback={secondBtnCallback ? secondBtnCallback : () => {}}/>
                }
            </div>
        </div>
    )
}

export default ButtonWrapper