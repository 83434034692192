import { useState } from "react";
import { IUser } from "../../Store/Reducers/user";
import icons from "../../Assets/standard-2.0.2/images/24/em-icons.svg";
import avatar from "../../Assets/images/default-avatar-icon-48px.svg";
import "./UserCard.scss";

interface IProps {
    user: IUser;
    selectedUserId?: string;
    callback: Function;
    isCheckIn?: boolean;
}

const UserCard: React.FC<IProps> = ({
    user,
    selectedUserId,
    callback,
    isCheckIn,
}) => {
    const [photoError, setPhotoError] = useState(false);

    return (
        <div
            className={`user-card ${
                selectedUserId === user.id ? "user-card--selected" : ""
            }`}
            onClick={() => callback(user)}
        >
            {isCheckIn !== undefined ? (
                <img
                    className="user-card__avatar"
                    alt="avatar"
                    src={
                        user.lanId !== undefined && !photoError
                            ? `https://mysite.na.xom.com/User%20Photos/Profile%20Pictures/${user.lanId.replace(
                                  /\\/g,
                                  "_"
                              )}_LThumb.jpg`
                            : avatar
                    }
                    onError={() => setPhotoError(true)}
                />
            ) : null}
            <div className="user-card__info">
                <div className="user-card__info--name">{user.name}</div>
                <div className="user-card__info--email">{user.email}</div>
            </div>
            {selectedUserId === user.id ? (
                <svg className="user-card__icon em-c-icon em-c-icon--small">
                     {" "}
                    <use
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        href={`${icons}#circle-check`}
                    ></use>
                </svg>
            ) : null}
        </div>
    );
};

export default UserCard;
