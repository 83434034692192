import ExportService from './ExportService'
import {store} from '../Store/store'

class EmailService {
    static async sendMail(data:object[], eventName:string, subject:string, sender:string, receiver:Array<string>, message:string, cc?:Array<string>){
        let csvData = ExportService.prepareData(data)
        let csvEncode =  window.btoa(csvData)
        let payload = { 
            "Subject": subject, 
            "Body": { 
                        "ContentType": "text/plain",
                        "Content": message
                    }, 
            "From": sender, 
            "ToRecipients": receiver, 
            "CcRecipients": cc,
            "Attachments": [ 
                { 
                    "Name": `${eventName}.csv`, 
                    "MimeType":"text/csv", 
                    "ContentBytes": csvEncode
                }
            ] 
        }

        let response = await fetch(`${process.env.REACT_APP_EVENTX_TRACKER_API_URL}/EmailSend?code=${process.env.REACT_APP_API_CODE}`,
            {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer ' + store.getState().user.eventXTrackerToken,
                },
                body: JSON.stringify(payload)  
            }
        )
        if(response.status !== 200){ throw response }
        return await response.json()
    }
}

export default EmailService