import React from 'react'
import './Tag.scss'

interface IProps{
    text: string
}

const Tag:React.FC<IProps> = ({text}) =>  {
    return (
        <div className="tag">
            {text}
        </div>
    )
}

export default Tag