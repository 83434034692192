import {store} from '../Store/store'

class SASTokenService {
    static async getTutorialURL(){
        let response = await fetch(`${process.env.REACT_APP_EVENTX_TRACKER_API_URL}/SASToken?code=${process.env.REACT_APP_API_CODE}`,
            {
                method:'GET',
                mode:'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer ' + store.getState().user.eventXTrackerToken,
                }
            }
        )
        if(response.status !== 200){ throw response }
        return await response.json()
    }
}

export default SASTokenService