import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DateIndicator from "../DateIndicator/DateIndicator";
import IconLabel from "../IconLabel/IconLabel";
import Button from "../Button/Button";
import Tag from "../Tag/Tag";
import {
  updateCurrEvent,
  updateIsLoadingCurrEvent,
} from "../../Store/Actions/app";
import { initState } from "../../Store/Reducers/app";
import { IEvent } from "../../Store/Reducers/event";
import "./EventCard.scss";

interface IProps {
  event: IEvent;
}

const EventCard: React.FC<IProps> = ({ event }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currButtonCallback, setCurrButtonCallback] = useState("");
  const currEvent = useSelector((state: any) => state.app.currEvent);
  const isLoadingCurrEvent = useSelector(
    (state: any) => state.app.isLoadingCurrEvent
  );

  const callback = (type: string) => {
    setCurrButtonCallback(type);
    dispatch(updateIsLoadingCurrEvent(true));
    dispatch(updateCurrEvent(event));
  };

  useEffect(() => {
    if (isLoadingCurrEvent === false && currEvent !== initState.currEvent) {
      if (currButtonCallback === "trackAttendance") {
        navigate(`/events/${event.eventId}/questions`);
      } else if (currButtonCallback === "setting") {
        navigate(`/events/${event.eventId}/setting/attendance`);
      }
    }
  }, [isLoadingCurrEvent, currButtonCallback]);

  return (
    <div className="event-card">
      <div className="event-card__body">
        <div className="event-card__body--group">
          <div className="event-card__body-calendar">
            <DateIndicator
              day={moment(event.startTime).format("D")}
              month={moment(event.startTime).format("MMM")}
            />
          </div>
          <div className="event-card__body-info">
            <div className="event-card__body-info--header">
              <div className="event-card__body-info--header__container">
                <span className="event-card__body-info--header__container__text">
                  {event.name}
                </span>
                {!event.isExist ? (
                  <span className="event-card__body-info--header__container__extend">
                    {" "}
                    (Cancelled)
                  </span>
                ) : null}
              </div>
              <div className="event-card__body-info--header__tag">
                {event.tags.map((tag: any) => (
                  <Tag key={`${event.eventId}-${tag.name}`} text={tag.name} />
                ))}
              </div>
            </div>
            <div className="event-card__body-info--detail">
              <div className="event-card__body-info--detail__item">
                <IconLabel
                  icon="calendar"
                  optionalLabel={`${moment(event.startTime).format(
                    "dddd, MMMM Do YYYY"
                  )}  at ${moment(event.startTime).format("hh:mm A")}`}
                />
              </div>
              <div className="event-card__body-info--detail__item">
                <IconLabel icon="map-w-pin" optionalLabel={event.location} />
              </div>
            </div>
            <div className="event-card__body-info--detail-non-flex">
              <div className="event-card__body-info--detail__item">
                <IconLabel
                  icon="group"
                  optionalLabel={`${event.participants} Registered`}
                />
              </div>
              <div className="event-card__body-info--detail__item">
                <IconLabel
                  optionalClass="event-card__body-info--detail__item--icon"
                  icon="circle-check"
                  optionalLabel={
                    event.checkedInAttendees !== null &&
                    event.checkedInAttendees !== 0
                      ? `${event.checkedInAttendees} Attendees checked in`
                      : `No one has checked in`
                  }
                />
                <IconLabel
                  optionalClass="event-card__body-info--detail__item--icon"
                  icon="user-1-plus"
                  optionalLabel={
                    event.walkedInAttendees !== null &&
                    event.walkedInAttendees !== 0
                      ? `${event.walkedInAttendees} Attendees walked in`
                      : `No one has walked in`
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="event-card__body-buttons">
          {moment(event.endTime).add(1, "days").isBefore(moment()) ||
          !event.isExist ? (
            <Button
              name="Check-in"
              optionalClass="event-card__body-buttons__track-attendance em-c-btn--disabled"
              callback={() => ({})}
            />
          ) : (
            <Button
              hasLoading
              name="Check-in"
              optionalClass="event-card__body-buttons__track-attendance em-c-btn--primary"
              callback={() => callback("trackAttendance")}
            />
          )}
          <Button
            hasLoading
            name="Manage"
            optionalClass="event-card__body-buttons__setting"
            callback={() => callback("setting")}
          />
        </div>
      </div>
    </div>
  );
};

export default EventCard;
