import React from "react";
import "./AttendeeCard.scss";

interface IProps {
  name: string;
  status: string;
}

const AttendeeCard: React.FC<IProps> = ({ name, status }) => {
  return (
    <div className="attendee-card">
      {status !== "" ? (
        <div
          className={`attendee-card__status ${
            status === "checked-in"
              ? "checked-in"
              : status === "walked-in"
              ? "walked-in"
              : "not-checked-in"
          }`}
        ></div>
      ) : null}
      <div className="attendee-card__name">{name}</div>
    </div>
  );
};

export default AttendeeCard;
