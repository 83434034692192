import {store} from '../Store/store'

export default class CheckInService {
    userId: string
    eventId: string
    guid: string

    constructor(userId:string, eventId:string, guid:string){
        this.userId = userId
        this.eventId = eventId
        this.guid = guid
    }

    async checkInByEmail(signal:AbortSignal){
        let data = {
            userId: this.userId,
            eventId: this.eventId,
            isBadge: false
        }
        let response = await fetch(`${process.env.REACT_APP_EVENTX_TRACKER_API_URL}/AttendeeCheckIn?code=${process.env.REACT_APP_API_CODE}`,
            {
                method:'POST',
                mode:'cors',
                cache: 'no-cache',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer ' + store.getState().user.eventXTrackerToken,
                },
                body: JSON.stringify(data)
                
            }
        )
        if(await response.status !== 200){
            throw response
        }
        let results = await response.json()
        return results
    }

    async checkInByBadge(){
        let data = {
            guid: this.guid,
            eventId: this.eventId,
            isBadge: true
        }
        let response = await fetch(`${process.env.REACT_APP_EVENTX_TRACKER_API_URL}/AttendeeCheckIn?code=${process.env.REACT_APP_API_CODE}`,
            {
                method:'POST',
                mode:'cors',
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer ' + store.getState().user.eventXTrackerToken,
                },
                body: JSON.stringify(data)  
            }
        )
        if(response.status !== 200){ throw response }
        return await response.json()
    }
}