import {useRef, useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {updateUserList, clearUserStore} from '../../Store/Actions/user'
import {IUser} from '../../Store/Reducers/user'
import LoadingIcon from '../LoadingIcon/LoadingIcon'
import UserCard from '../UserCard/UserCard'
import './EmailInput.scss'

interface IProps {
    receiverList: Array<IUser>,
    addEmailCallback: Function
}

const EmailInput:React.FC<IProps> = ({receiverList, addEmailCallback}) => {
    const dispatch = useDispatch()
    const userList = useSelector((state:any) => state.user.userList)
    const isUserNotFound = useSelector((state:any) => state.user.isUserNotFound)
    const [emailInput, setEmailInput] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const inputRef = useRef(document.createElement('input'))

    const selectUserCallback = (user:IUser) => {
        setEmailInput('')
        focusInput()
        if(!receiverList.find((x:IUser) => x.email === user.email)){
            let newReceivers = [...receiverList]
            newReceivers.push(user)
            addEmailCallback(newReceivers)
        }
    }

    const emailInputCallback = (value:string) => {
        dispatch(clearUserStore())
        setEmailInput(value)
        if(value.length >= 3){
            setIsLoading(true)
            dispatch(updateUserList(value, 'User'))
        }
    }

    const focusInput = () =>{
        inputRef.current.focus()
    }

    const removeEmail = (index:number) => {
        let newReceivers = [...receiverList]
        newReceivers.splice(index, 1)
        addEmailCallback(newReceivers)
    }

    useEffect(() => {
        if(userList.length > 0 || isUserNotFound){
            setIsLoading(false)
        }
    }, [userList, isUserNotFound])

    return (
        <div className="email-input" onClick={() => focusInput()}>
            <div  className="email-input__container">
                <div className="email-input__container--inner">
                    {receiverList.map((user:IUser, index:number) => 
                        <span key={user.email} className="email-input__container--inner-list">
                            {user.name}
                            <span className="email-input__container--inner-list--remove" onClick={() => removeEmail(index)}>×</span>
                        </span>
                    )}
                    <input  
                        className="email-input__container--inner-list--field" 
                        ref={inputRef}
                        value={emailInput} 
                        onChange={(e) => emailInputCallback(e.target.value)} 
                        onBlur={() => isUserNotFound ? dispatch(clearUserStore()): null}
                    />
                </div>
                {isLoading ? <LoadingIcon optionalClass="email-input__container--loading" /> : null}         
            </div>
            {userList.length > 0 && emailInput.length >= 3 ?
            <div className="email-input__user-picker">
                <div className="email-input__user-picker--list">
                    {userList.map((user:IUser) => 
                    <UserCard key={user.id} user={user} callback={() => selectUserCallback(user)}/>
                    )}
                </div>
            </div>
            : isUserNotFound ? 
            <div className="email-input__user-picker--list no-data"> 
                {`We could not find ${emailInput} in the system.`}
            </div> 
            : null}
        </div>
    )
}

export default EmailInput