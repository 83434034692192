import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import YearPicker from "../YearPicker/YearPicker";
import MonthPicker from "../MonthPicker/MonthPicker";
import EventFilter from "../EventFilter/EventFilter";
import EventTypesBar from "../EventTypesBar/EventTypesBar";
import "./SubHeader.scss";

const EventSubHeader: React.FC = () => {
    const isLoading = useSelector((state: any) => state.app.isLoading);
    const location = useLocation();

    return (
        <div className="sub-header">
            {location.pathname === "/whatsnew" ? (
                <div className="em-l-container">
                    <div className="sub-header__container">
                        <div className="sub-header__container__title">
                            What's New?
                        </div>
                    </div>
                    <div className="sub-header__container">
                        <div className="sub-header__container__sub-title">
                            We are working hard on EventX Tracker, take a look:
                        </div>
                    </div>
                </div>
            ) : (
                <div className="em-l-container">
                    <div className="sub-header__container">
                        <div className="sub-header__container__title">
                            My Events
                        </div>
                        {isLoading ? null : <EventTypesBar />}
                    </div>
                    <div className="sub-header__filter">
                        <YearPicker />
                        <MonthPicker />
                        <EventFilter />
                    </div>
                </div>
            )}
        </div>
    );
};

export default EventSubHeader;
