import React from 'react'
import './DateIndicator.scss'

interface IProps {
    month: string, 
    day: string
}

const DateIndicator:React.FC<IProps> = ({month, day}) =>  {
    return (
        <div className="date-indicator-wrapper">
            <div className="date-indicator">
                <div className="date-indicator__header">{month}</div>
                <div className="date-indicator__body">{day}</div>
            </div>
        </div>
    )
}

export default DateIndicator