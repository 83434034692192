export interface ITag{
    name: string,
    isActive: boolean
}

export interface IEvent {
    eventId: string,
    name: string,
    responsible?: string,
    details?: string,
    location: string,
    startTime: Date,
    endTime: Date,
    seats?: number,
    participants: number,
    createdAt?: Date,
    createdBy?: string,
    timezoneId?: string,
    tags: Array<Object>,
    allowWalkIn: boolean,
    allowExceedCapacity: boolean,
    isFinished?: boolean,
    walkedInAttendees: number,
    checkedInAttendees: number,
    isExist: boolean,
    understandDisclaimer: boolean,
}

interface IState {
    events: Object
}

const initState:IState = {
    events: {'Jan':[], 'Feb':[], 'Mar':[], 'Apr':[], 'May':[], 'Jun':[], 'Jul':[], 'Aug':[], 'Sep':[], 'Oct':[], 'Nov':[], 'Dec':[]}
}

export default (state:IState = initState, action:any) => {
    switch (action.type) {
        case 'UPDATE_EVENTS':
            return {...state, events:action.payload}
        case 'CLEAR_EVENTS_STORE':
                return {...state, events:initState.events}
        default:
            return state
    }
}