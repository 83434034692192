import React from 'react'
import {useDispatch} from 'react-redux'
import Button from '../Button/Button'
import {exportCSV, prepareDataObject} from '../../Store/Actions/setting'
import './PreviewData.scss'

interface IProps {
    careerConnectId: string,
    attendeeType: string,
    closeCallback: Function
}

const PreviewData:React.FC<IProps> = ({careerConnectId, attendeeType, closeCallback}) => {  
    const dispatch = useDispatch()
    let attendees = prepareDataObject(attendeeType, careerConnectId)

    const exportCSVCallback = () => {
        dispatch(exportCSV(attendeeType, careerConnectId))
    }

    return (
        <div className="overlay">
            <div className="preview-data">
                <div className="preview-data__table">
                    <div className="em-c-table-object">
                        <div className="em-c-table-object__body">
                            <div className="em-c-table-object__body-inner">
                                <table className="em-c-table em-c-table--condensed">
                                    <thead className="em-c-table__header">
                                        <tr className="em-c-table__header-row">
                                            {careerConnectId  !== "" ? <th scope="col" className="em-c-table__header-cell ">Course ID</th> : null} 
                                            <th scope="col" className="em-c-table__header-cell ">Event Name</th>                       
                                            <th scope="col" className="em-c-table__header-cell ">Event Start Date</th>
                                            <th scope="col" className="em-c-table__header-cell ">Event Start Time(UTC)</th>
                                            <th scope="col" className="em-c-table__header-cell ">EventX Contact</th>
                                            <th scope="col" className="em-c-table__header-cell ">GUID</th>
                                            <th scope="col" className="em-c-table__header-cell ">Name</th>
                                            <th scope="col" className="em-c-table__header-cell ">Email</th>
                                            <th scope="col" className="em-c-table__header-cell ">LANID</th>
                                            <th scope="col" className="em-c-table__header-cell ">Status</th>
                                        </tr>
                                    </thead>
                                    {attendees.length !== 0 ?
                                    <tbody className="em-c-table__body ">
                                        {attendees.map(attendee => 
                                            <tr className="em-c-table__row" key={`${attendee.eventName} ${attendee.name}`}>
                                                {careerConnectId  !== "" ? 
                                                <td className="em-c-table__cell em-js-cell" >
                                                    {careerConnectId}
                                                </td> 
                                                : null} 
                                                <td className="em-c-table__cell em-js-cell" >
                                                    {attendee.eventName}
                                                </td> 
                                                <td className="em-c-table__cell em-js-cell" >
                                                    {attendee.eventDate}
                                                </td>  
                                                <td className="em-c-table__cell em-js-cell" >
                                                    {attendee.startTime}
                                                </td>  
                                                <td className="em-c-table__cell em-js-cell" >
                                                    {attendee.eventXContact}
                                                </td>
                                                <td className="em-c-table__cell em-js-cell" >
                                                    {attendee.GUID}
                                                </td>  
                                                <td className="em-c-table__cell em-js-cell" >
                                                    {attendee.name}
                                                </td>  
                                                <td className="em-c-table__cell em-js-cell" >
                                                    {attendee.email}
                                                </td>   
                                                <td className="em-c-table__cell em-js-cell" >
                                                    {attendee.LanId}
                                                </td>   
                                                <td className="em-c-table__cell em-js-cell" >
                                                    {attendee.userStatus.toUpperCase().replace(/-/g, ' ')}
                                                </td>                                        
                                            </tr>
                                        )}
                                    </tbody>
                                    :
                                    <tbody className="preview-data__nodata">
                                        {`This event has no ${attendeeType === 'all' ? '' : attendeeType.replace(/-/g, ' ')} attendees.`}
                                    </tbody>
                                    }
                                    <tfoot className="em-c-table__footer">
                                        <tr className="em-c-table__footer-row"></tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>         
                    </div>           
                </div>
                <div className="preview-data__buttons">
                    <Button name="Download CSV" optionalClass="em-c-btn--primary em-u-margin-right" callback={() => exportCSVCallback()}/>
                    <Button name="Close" callback={() => closeCallback()}/>  
                </div> 
            </div>
        </div>     
    )
}

export default PreviewData