import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { IEvent } from "../../Store/Reducers/event";
import {
    getEventsFromEventX,
    clearEventStore,
} from "../../Store/Actions/event";
import {
    updateCurrYear,
    updateIsLoading,
    clearErrorResponseStore,
    clearAppStore,
} from "../../Store/Actions/app";
import SubHeader from "../../Components/SubHeader/SubHeader";
import EventCard from "../../Components/EventCard/EventCard";
import ErrorModal from "../../Components/ErrorModal/ErrorModal";
import LoadingIndicator from "../../Components/LoadingIndicator/LoadingIndicator";
import ErrorCard from "../../Components/ErrorCard/ErrorCard";
import "./Homepage.scss";

interface IProps {
    params: string;
}

const Homepage: React.FC<IProps> = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isShowingError, setIsShowingError] = useState(false);
    const events = useSelector((state: any) => state.app.eventsOfCurrMonth);
    const errorResponse = useSelector((state: any) => state.app.errorResponse);
    const isLoading = useSelector((state: any) => state.app.isLoading);
    const currYear = useSelector((state: any) => state.app.currYear);
    const eventXTrackerToken = useSelector(
        (state: any) => state.user.eventXTrackerToken
    );

    const processCallback = () => {
        
        navigate(
            `events?year=${moment().format("YYYY")}&month=${moment().format(
                "MMM"
            )}`
        );
        dispatch(
            updateCurrYear(moment().format("YYYY"), moment().format("MMM"))
        );
        setIsShowingError(false);
        dispatch(clearErrorResponseStore());
        
    };

    const pathChangeCallback = (year: any, month: any) => {
        navigate(
            `/events?year=${year}${
                month !== undefined ? `&month=${month}` : ""
            }`
        );
        
    };

    useEffect(() => {
        if (errorResponse.message !== "") {
            setIsShowingError(true);
        }
    }, [errorResponse]);

    useEffect(() => {
        dispatch(clearAppStore());
        dispatch(clearEventStore());
        dispatch(updateIsLoading(true));
        if (eventXTrackerToken !== "") {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let monthParam = params.get("month");
            let yearParam = params.get("year");

            if (yearParam !== null) {
                dispatch(
                    updateCurrYear(
                        yearParam,
                        monthParam !== null ? monthParam : undefined,
                        pathChangeCallback
                    )
                );
            } else {
                navigate(
                    `events?year=${currYear}&month=${moment().format("MMM")}`
                );
                dispatch(getEventsFromEventX(currYear, moment().format("MMM")));
                
            }
        }
    }, [eventXTrackerToken]);

    return (
        <div className="homepage">
            <SubHeader />
            <div className="homepage em-l-container">
                {isLoading ? (
                    <div className="homepage__loading">
                        <LoadingIndicator />
                    </div>
                ) : (
                    <div>
                        {events && events.length > 0 ? (
                            events.map((event: IEvent) => (
                                <EventCard key={event.eventId} event={event} />
                            ))
                        ) : (
                            <ErrorCard />
                        )}
                    </div>
                )}
            </div>
            {isShowingError ? (
                <ErrorModal
                    question={errorResponse.message}
                    firstBtnName={
                        errorResponse.message === "Session Expired"
                            ? "Refresh"
                            : "Try again"
                    }
                    firstBtnCallback={processCallback}
                />
            ) : null}
        </div>
    );
};

export default Homepage;
