import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TutorialModal from "../TutorialModal/TutorialModal";
import "./Header.scss";

const Header: React.FC = () => {
    const location = useLocation();
    const [isVisible, setVisible] = useState(false);
    const [isShowingModal, setIsShowingModal] = useState(false);

    useEffect(() => {
        !location.pathname.includes("/questions") &&
        !location.pathname.includes("/checkin")
            ? setVisible(true)
            : setVisible(false);
    }, [location]);

    return (
        <div>
            {isVisible ? (
                <div className="header em-c-header" role="banner">
                    <div className="header em-l-container em-c-header__inner">
                        <div className="header em-c-header__body">
                            <div className="header em-c-header__title-container">
                                <div className="header em-c-header__title">
                                    <a
                                        href="/events"
                                        className={`header em-c-header__title-link ${
                                            location.pathname === "/"
                                                ? "landing"
                                                : ""
                                        }`}
                                    >
                                        Event<span>X</span> Tracker
                                    </a>
                                    {location.pathname === "/" ? (
                                        <div
                                            className="header em-c-header__tutorial-link"
                                            onClick={() =>
                                                setIsShowingModal(true)
                                            }
                                        >
                                            Tutorial
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {isShowingModal ? (
                <TutorialModal closeCallback={() => setIsShowingModal(false)} />
            ) : null}
        </div>
    );
};

export default Header;
