import React from "react";
import icons from "../../Assets/standard-2.0.2/images/24/em-icons.svg";
import "./IconLabel.scss";

interface IProps {
    icon: string;
    optionalLabel?: string;
    optionalClass?: string;
}

const IconLabel: React.FC<IProps> = ({
    icon,
    optionalLabel,
    optionalClass,
}) => {
    return (
        <div className={`${optionalClass} icon-label`}>
            <svg className="em-c-icon icon-label__icon">
                <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    href={`${icons}#${icon}`}
                ></use>
            </svg>
            {optionalLabel ? (
                <span className="icon-label__text">{optionalLabel}</span>
            ) : null}
        </div>
    );
};

export default IconLabel;
