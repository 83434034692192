export interface IUser {
    id: string,
    name: string,
    email: string,
    lanId?: string
}

export interface IAdmin {
    userId: string,
    eventId: string, 
    type: string,
    name: string,
    email: string,
    LanId: string,
    GUID: string,
    eGUID: string,
}

interface IState {
    isLoadingAdminList: boolean,
    adminList: Array<IAdmin>
}

const initState:IState = {
    isLoadingAdminList: false,
    adminList: []
}

export default (state:IState = initState, action:any) => {
    switch (action.type) {   
        case 'UPDATE_ADMIN_LIST':
            return {...state, adminList:action.payload}
        case 'UPDATE_IS_LOADING_ADMIN_LIST':
            return {...state, isLoadingAdminList:action.payload}
        default:
            return state
    }
}