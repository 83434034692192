import React from 'react'
import './SwitchToggle.scss'

interface IProps {
    label: string,
    callback: Function,
    isActive: boolean,
    optionalClass?: string,
    isDisable?: boolean
}

const SwitchToggle:React.FC<IProps> = ({label, callback, isActive, optionalClass, isDisable}) => {
    return (
        <div className={`${optionalClass} switch-toggle ${isDisable ? 'disabled': ''}`}>
            <div className="switch-toggle__name">{label}</div>
            <label className="switch">
            {isDisable ? null : <input type="checkbox" checked={isActive} onChange={() => callback()} />}
                <span className="slider round"></span>
            </label>
        </div>
    )
}

export default SwitchToggle