import React from 'react'
import './LoadingIcon.scss'

interface IProps {
    optionalClass?: string
}

const LoadingIcon:React.FC<IProps> = ({optionalClass}) => {
    return (
        <div className={`spinner ${optionalClass}`}>
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
        </div>
    )
}

export default LoadingIcon
