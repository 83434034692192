export interface IAttendee {
    eventId: string,
    userId: string,
    name: string,
    email: string,
    userStatus: string,
    LanId: string,
    eventName?: string,
    eventDate?: Date,
    startTime?: Date,
    courseId?: string,
    checkedInDate: Date,
    GUID: string,
    eGUID: string,
}

interface IState {
    isLoadingAttendeeList: boolean,
    attendeeList: Array<IAttendee>,
}

const initState:IState = {
    isLoadingAttendeeList: false,
    attendeeList: []
}

export default (state:IState = initState, action:any) => {
    switch (action.type) {   
        case 'UPDATE_ATTENDEE_LIST':
            return {...state, attendeeList:action.payload}
        case 'UPDATE_IS_LOADING_ATTENDEE_LIST':
                return {...state, isLoadingAttendeeList:action.payload}
        default:
            return state
    }
}