import {store} from '../Store/store'

class EventService {
    static async getEventsAsync(year:string, signal:AbortSignal, month?:string){
        let response = await fetch(`${process.env.REACT_APP_EVENTX_TRACKER_API_URL}/EventMonthGet?code=${process.env.REACT_APP_API_CODE}${month === undefined ? "" : `&month=${month}`}&year=${year}`,
            {
                method:'GET',
                mode:'cors',
                cache: 'no-cache',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer ' + store.getState().user.eventXTrackerToken
                }
            }
        )
        if(response.status !== 200){ throw response }
        return await response.json()
    }

    static async duplicateEventAsync(data:any){
        let response = await fetch(`${process.env.REACT_APP_EVENTX_TRACKER_API_URL}/EventDuplicate?code=${process.env.REACT_APP_API_CODE}`,
            {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer ' + store.getState().user.eventXTrackerToken
                },
                body:JSON.stringify(data)
            }
        )
        if(response.status !== 200 && response.status!==201){  throw response }
        return await response.json()
    }
    
    static async getEventInfoAsync(eventId:string, signal:AbortSignal){
        let response = await fetch(`${process.env.REACT_APP_EVENTX_TRACKER_API_URL}/EventInfoGet?code=${process.env.REACT_APP_API_CODE}&eventId=${eventId}`,
            {
                method:'GET',
                mode:'cors',
                cache: 'no-cache',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer ' + store.getState().user.eventXTrackerToken,
                }
            }
        )
        if(response.status !== 200){ throw response }
        return await response.json()
    }

    static async updateAllowWalkIn(data:any){
        let response = await fetch(`${process.env.REACT_APP_EVENTX_TRACKER_API_URL}/EventSetting?code=${process.env.REACT_APP_API_CODE}`,
            {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': 'Bearer ' + store.getState().user.eventXTrackerToken,
                },
                body:JSON.stringify(data)
            }
        )
        if(response.status !== 200){ throw response }
        return await response.json()
    }
}

export default EventService